import React from "react";

import { colors } from '../../ui/variables';

const HomeIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
    <title>Home</title>
    <polygon
      fill={color || colors.black}
      points="12 0 0 11 4 11 4 20 8 20 8 13.9 13.14 13.9 13.14 20 20 20 20 11 24 11 12 0"
    />
  </svg>
);

export default HomeIcon;
