import styled from 'styled-components';

import { breakpoints, colors, fontFamily, functions, grid } from '../../ui/variables.js';

export const PavementsListStyle = styled.ul`
  color: ${colors.white};
  display: flex;
  flex-direction: column-reverse;
  padding: 12px ${grid.minOuter}px 0;

  .pavement-item {
    border-top: solid 2px ${colors.white};
    min-height: ${functions.responsive.vh(
      80,
      88,
      breakpoints.iphone.height,
      breakpoints.iphone8.height
    )};
    padding: 2px 0 12px 50px;
    position: relative;

    @media (min-height: ${breakpoints.iphoneSe.height-30}px) {
      border-top: solid 4px ${colors.white};
    }

    @media (min-height: ${breakpoints.iphone8.height-50}px) {
      min-height: 114px;
      padding: 8px 0 6px 88px;
    }

    &:first-child {
      border-bottom: solid 2px ${colors.white};

      @media (min-height: ${breakpoints.iphoneSe.height}px) {
        border-bottom: solid 4px ${colors.white};
      }
    }

    .go-to-pavement {
      display: block;
      padding: 0px 24px 0px 0px;
      text-align: left;
      width: 100%;

      .pavement-title {
        font-weight: normal;

        .pavement-code {
          display: block;
          top: 10px;
          left: 5px;
          position: absolute;
          width: 35px;
          height: 35px;
          color: transparent;

          &.number0 {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path fill="white" class="cls-1" d="M60.24,303.22Q42,258.52,42,199.52T60.24,95.82q18.23-44.71,54.28-69.88T200.13.77q49.56,0,85.61,25.17t54.41,69.88q18.35,44.7,18.35,103.7t-18.35,103.7q-18.36,44.71-54.41,69.88t-85.61,25.17q-49.56,0-85.61-25.17T60.24,303.22Zm88.24-193.38q-16.78,32.79-16.78,89.68t16.78,89.67Q165.25,322,200.13,322t51.79-32.78q16.91-32.78,16.91-89.67t-16.91-89.68Q235,77.08,200.13,77.07T148.48,109.84Z"/></svg>') no-repeat;
          }
          &.number1 {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path fill="white" d="M210.05,5h68.17V392.24H189.07V139.74H99.92V72.61L132.17,70q38.28-2.88,56.11-17.7T210.05,5Z"/></svg>') no-repeat;
          }
          &.number2 {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path fill="white" d="M62.08,326.69l103.05-85.22q15.74-12.84,24-19.93t20.19-18a191.15,191.15,0,0,0,18.09-18.35q6.16-7.47,12.59-16.91a62.77,62.77,0,0,0,11.66-37q0-23.86-14-39.07T199,77.07q-24.39,0-39.07,16.65T145.2,135a106.9,106.9,0,0,0,6,34.87h-85A122.78,122.78,0,0,1,59.2,129q0-37.5,19.53-67a129.49,129.49,0,0,1,51.79-45.36Q162.77.77,201.57.77q63.19,0,101.22,34.48t38,90.06a128.07,128.07,0,0,1-6.69,41.3A132,132,0,0,1,314.19,204a295.51,295.51,0,0,1-27.79,32,453.85,453.85,0,0,1-34.75,30.67L190.3,315.42H342.38v76.82H62.08Z"/></svg>') no-repeat;
          }
          &.number3 {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path fill="white" d="M202.62,398.27q-61.87,0-103.44-31.2T49.76,278.44l87.31-13.89q3.15,27.79,20.72,43.65t43.26,15.87q25.17,0,41-12.72T258,275.3q0-22.82-16.65-33.43t-45.5-10.62H159.62V159.66h35.14q26.2,0,41-10.35t14.82-31.6q0-19.67-13.11-31.2T201.57,75Q177.2,75,161.46,90.7t-19.93,41.17l-82.33-9.7q7.06-52.44,46.14-86.92T205.51.77q60,0,95.7,31.2t35.66,80q0,28.59-14.68,50.74T285,193.49q27.78,10.75,44.18,33t16.39,56.11q0,49-39.47,82.33T202.62,398.27Z"/></svg>') no-repeat;
          }

          @media (min-height: ${breakpoints.iphoneSe.height-30}px) {
            top: 10px;
            left: 2px;
            width: 44px;
            height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height-50}px) {
            top: 12px;
            left: 10px;
            width: 66px;
            height: 66px;
          }
        }

        .pavement-name {          
          ${fontFamily.sohneDreiviertelfett};
          font-size: 24px;
          letter-spacing: 0;
          text-transform: uppercase;
          line-height: 36px;

          @media (min-height: ${breakpoints.iphoneSe.height-30}px) {
            font-size: 30px;
            letter-spacing: -0.45px;
            line-height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height-50}px) {
            font-size: 30px;
            letter-spacing: -0.45px;
            line-height: 34px;
          }
        }
      }

      .pavement-subtitle {
        ${fontFamily.sohneKraftig};
        color: ${colors.darkGray};
        font-size: ${functions.responsive.vh(
          12,
          14,
          breakpoints.iphone.height,
          breakpoints.iphone8.height
        )};
        font-weight: normal;
        line-height: ${functions.responsive.vh(
          14,
          18,
          breakpoints.iphone.height,
          breakpoints.iphone8.height
        )};

        @media (min-height: ${breakpoints.iphoneSe.height-30}px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media (min-height: ${breakpoints.iphone8.height-50}px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .icon {
        display: block;
        height: 16px;
        line-height: 0;
        position: absolute;
        right: 0;
        top: 20px;
        width: 16px;
      }
    }
  }
`;
