import styled from 'styled-components';

import { breakpoints, colors, zIndex } from './variables';

const PageTransitionStyle = styled.div`
  background-color: ${colors.black};
  max-width: ${breakpoints.ipad.width}px;
  min-height: calc(100vh - 76px);
  padding-bottom: 76px;
  width: 100%;

  @media (min-height: ${breakpoints.iphoneSe.height}px) {
    min-height: calc(100vh - 88px);
    padding-bottom: 88px;
  }

  @media (min-height: ${breakpoints.iphone8.height}px) {
    min-height: calc(100vh - 94px);
    padding-bottom: 94px;
  }

  &.page-transition-appear,
  &.page-transition-appear-done,  
  &.page-transition-enter,
  &.page-transition-enter-done {
    position: relative;
    top: 0;
  }

  &.page-transition-enter-active {
    ${zIndex.sky};
    min-height: calc(100vh - 60px);
    position: fixed;
    top: 60px;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      min-height: calc(100vh - 58px);
      top: 62px;
    }

    @media (min-height: ${breakpoints.iphone8.height}px) {
      min-height: calc(100vh - 56px);
    }

    @media (min-height: ${breakpoints.iphonePlus.height}px) {
      top: 68px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      min-height: calc(100vh - 76px);
      top: 88px;
    }
  }

  &.page-transition-exit-active {
    position: relative;
  }

  &.page-transition-exit-done {
    display: none;
  }
`;

export default PageTransitionStyle;
