import en from './en-US.js'
import es from './es-ES.js'
import pt from './pt-BR.js'
import libras from './libras.js'

export default {
  ...en,
  ...es,
  ...pt,
  ...libras
}