import React from 'react';
import { Carousel } from 'react-responsive-carousel'; //http://react-responsive-carousel.js.org/storybook/

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SlideshowStyle } from './SlideshowStyle.js';

const SlideShow = ({ content }) => (
  <SlideshowStyle aria-hidden={true}>
    <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      autoPlay={false}
      infiniteLoop={true}
      interval={4000}
    >
      {content.map(item => (
        <div
          key={item.id}
          className="slideshow-item"
          style={{
            backgroundImage: `url(${item.url})`
          }}
        >
          <img
            src={item.url}
            className="item-image-file"
            alt={item.alternativeText}
          />
        </div>
      ))}
    </Carousel>
  </SlideshowStyle>
);

export default SlideShow;
