import styled from 'styled-components';

import { breakpoints, colors, fontFamily, functions, zIndex } from '../../ui/variables.js';

export const NavigationBarStyle = styled.nav`
  ${zIndex.sky};
  bottom: ${functions.responsive.vh(
    14,
    24,
    breakpoints.iphone.height,
    breakpoints.iphoneX.height)};
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  @media (min-height: ${breakpoints.iphoneX.height}px) {
    bottom: 24px;
  }

  .navigation-list {
    ${fontFamily.sohneDreiviertelfett};
    align-items: center;
    background-color: ${colors.white};
    background-image: linear-gradient(180deg, ${colors.white} 0%, ${colors.darkGray25} 80%);
    border-radius: 8px;
    box-sizing: content-box;
    box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.16);
    color: ${colors.veryDarkGray};
    display: flex;
    font-size: ${functions.responsive.vh(
      14,
      18,
      breakpoints.iphone.height,
      breakpoints.iphoneX.height)};
    justify-content: space-between;
    line-height: ${functions.responsive.vh(
      19,
      24,
      breakpoints.iphone.height,
      breakpoints.iphoneX.height)};
    min-width: 264px;
    padding: ${functions.responsive.vh(
      14,
      18,
      breakpoints.iphone.height,
      breakpoints.iphoneX.height)} 36px;

      @media (min-height: ${breakpoints.iphone.height}px) {
        padding: ${functions.responsive.vh(
          14,
          18,
          breakpoints.iphone.height,
          breakpoints.iphoneX.height)} 30px;
          border-radius: 8px;
          box-sizing: content-box;
      }

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      padding: ${functions.responsive.vh(
        14,
        18,
        breakpoints.iphone.height,
        breakpoints.iphoneX.height)} 30px;
        border-radius: 8px;
        min-width: 235px;
        box-sizing: content-box;
    }

    @media (min-height: ${breakpoints.iphone8.height}px) {
      padding: ${functions.responsive.vh(
        14,
        18,
        breakpoints.iphone.height, breakpoints.iphoneX.height)} 26px;
        border-radius: 8px;
        box-sizing: content-box;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      border-radius: 8px;
      font-size: 18px;
      line-height: 24px;
      min-width: 300px;
      padding: 18px 27px;
    }

    
    &.left,
    &.center, 
    &.right {
      .enabled {
        .text-cont{
          color: #FFF;
        }
      }
    }

    &.left {
      &.locale-pt,
      &.locale-libra,
      &.locale-es {
        &:before {
          width: 98px;
          height: 41px;
          box-sizing: content-box;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            width: 98px;
            height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height}px) {
            width: 97px;
            height:48px;
          }

          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            width: 90px;
            height: 50px;
          }

          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 104px;
            height: 51px;
          }
        }
      }
      &.locale-en {
        &:before {
          width: 98px;
          height: 41px;
          box-sizing: content-box;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            width: 98px;
            height: 44px;
          }
          @media (min-height: ${breakpoints.iphone8.height}px) {
            width: 97px;
            height:48px;
          }
          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            width: 90px;
            height: 50px;
          }
          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 90px;
            height:51px;
          }
        }
      }

      &:before {
        background-color: ${colors.strongBlue};
        left: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-sizing: content-box;

        @media (min-height: ${breakpoints.iphoneSe.height}px) {
          left: 5px;
        }

        @media (min-height: ${breakpoints.iphone8.height}px) {
          left: 5px;
        }
      }
    }

    &.center {
      &.locale-pt,
      &.locale-libra,
      &.locale-es {
        &:before {
          left: calc(50% - -5px);
            width: 70px;
            height: 41px;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            left: calc(50% - -5px);
            width: 70px;
            height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height}px) {
            left: calc(50% - -5px);
            width: 75px;
            height:48px;
          }

          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            left: calc(50% - -7px);
            width: 85px;
            height: 50px;
          }

          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 90px;
            height: 51px;
          }
        }
      }

      &.locale-en {
        &:before {
          left: calc(50% - -3px);
            width: 70px;
            height: 41px;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            left: calc(50% - -3px);
            width: 70px;
            height: 44px;
          }
          @media (min-height: ${breakpoints.iphone8.height}px) {
            left: calc(50% - -5px);
            width: 75px;
            height:48px;
          }
          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            left: calc(50% - -7px);
            width: 85px;
            height: 50px;
          }
          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 90px;
            height: 51px;
          }
        }
      }

      &:before {
        background-color: ${colors.vividOrange};
        transform: translate3d(-50%, 0, 0);
        left: 50%;
        border-radius: 0px;
        box-sizing: content-box;
      }
    }

    &.right {
      &.locale-pt,
      &.locale-libra,
      &.locale-es {
        &:before {
          width: 85px;
          height: 41px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          box-sizing: content-box;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            width: 90px;
            height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height}px) {
            width: 85px;
            height:48px;
          }

          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            width: 90px;
            height: 50px;
          }

          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 92px;
            height: 51px;
          }
        }
      }

      &.locale-en {
        &:before {
          width: 85px;
          height: 41px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;

          @media (min-height: ${breakpoints.iphoneSe.height}px) {
            width: 90px;
            height: 44px;
          }

          @media (min-height: ${breakpoints.iphone8.height}px) {
            width: 85px;
            height: 48px;
          }

          @media (min-height: ${breakpoints.iphonePlus.height}px) {
            width: 90px;
            height: 50px;
          }

          @media (min-height: ${breakpoints.iphoneX.height}px) {
            width: 80px;
            height: 51px;
          }
        }
      }

      &:before {
        background-color: ${colors.vividRed};
        left: calc(100% - 5px);
        transform: translate3d(-100%, 0, 0);

        @media (min-height: ${breakpoints.iphoneSe.height}px) {
          left: calc(100% - 5px);
        }

        @media (min-height: ${breakpoints.iphone8.height}px) {
          left: calc(100% - 5px);
        }
      }
    }

    &.none {
      &:before {
        background-color: transparent;
        left: initial;
        transform: translate3d(0, 0, 0);
        transition: background-color 300ms;
      }
    }

    &:before {
      ${zIndex.floor};
      background-color: transparent;
      content: '';
      display: block;
      height: 51px;
      left: 0;
      position: absolute;
      transition: background-color 300ms, left 300ms, transform 300ms, width 300ms;
      width: 50px;
      border-radius: 5px;
    }

    &:before {
      top: 4px;

      @media (min-height: ${breakpoints.iphoneSe.height}px) {
        top: 4px;
      }

      @media (min-height: ${breakpoints.iphone8.height}px) {
        top: 4x;
      }

      @media (min-height: ${breakpoints.iphonePlus.height}px) {
        top: 4px;
      }
      @media (min-height: ${breakpoints.iphoneX.height}px) {
        top: 4px;
      }
    }

    .navigation-item {
      ${fontFamily.sohneDreiviertelfett};
      display: inline-block;
      font-size: inherit;
      line-height: inherit;
      margin-right: 24px;

      @media (min-width: ${breakpoints.iphoneX.width}px) {
        margin-right: 36px;
      }

      &:last-child {
        margin-right: 0;
      }

      .navigation-button {
        ${zIndex.sky};
        display: block;

        .text-cont {
          ${zIndex.hills};
          position: relative;
          top:2px;
        }
      }
    }
  }
`;
