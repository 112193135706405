import React from "react";

import { colors } from '../../ui/variables';

const StarIcon = ({ color, isFilled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 38">
  <path
    d="M20,6.09l3.51,10.09,10.69.22-8.52,6.45,3.1,10.22L20,27l-8.78,6.1,3.1-10.22L5.8,16.4l10.69-.22L20,6.09M20,0,15.06,14.21,0,14.52,12,23.6,7.64,38,20,29.41,32.36,38,28,23.6l12-9.08-15.06-.31L20,0Z"
    fill={color || colors.black}
  />
  <path
    d="M7.64,38,12,23.6,0,14.52l15.06-.31L20,0l4.94,14.21L40,14.52,28,23.6,32.36,38,20,29.41Z"
    fill={isFilled ? (color || colors.black) : 'transparent'}
  />
  </svg>
);

export default StarIcon;
