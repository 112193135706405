import React from "react";

const DefaultArrow = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polygon
      fill={color || '#fff'}
      points="16.39 24 4.39 12 16.39 0 19.14 2.74 9.88 12 19.14 21.26 16.39 24"
    />
  </svg>
);

export default DefaultArrow;
