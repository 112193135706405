import {LOCALES} from '../locales'

export default {
    [LOCALES.SPANISH]:{
        'nameMuseum': 'Museu da Língua Portuguesa',
        'titleFeedback': 'Evalúe la aplicación',
    
        'code': 'Código', 
        'list': 'Índice',
        'map': 'Mapa',

        'pageNotFound': 'Página no encontrada',

        'VisitOurSite': 'Visite nuestro sitio web',

        'hello': 'Hola',
        'descriptionFeedbak' : '¿Cuál es su evaluación de la experiencia de uso de esta aplicación?',
        'placeholderTxtBoxFeedback' : 'Deje aquí comentarios y sugerencias',
        'submit': 'Enviar',
        'back' : 'Volver',

        'readMore':'Leer más',
        'readLess':'Leer menos ',

        'btnEnterCode' : 'Accesar',
        'msgQRCode':'Ingrese el código de la instalación o use la cámara de su teléfono móvil para escanear el código QR',
        'msgQRCodeError':'Código de instalación no encontrado', 
        'allowCam':'Permitir el acceso a la cámara',

        'thanks':'Gracias',
        'continue': 'Seguir',

        'contentNotAvailable' : 'Contenido no disponible em español',

        'feedback': 'Satisfação',

        'collectMenu': 'Recoger el menú',

        'cardinal0': ' ',
        'cardinal1': 'Primer',
        'cardinal2': 'Segundo',
        'cardinal3': 'Tercer',

        'floor': 'Piso',

        'playAudio': 'Reproducir audio',
        'pauseAudio': 'Pausar áudio',

        'and': 'y',
        'minutes': 'minutos',
        'minute': 'minuto',
        'seconds': 'segundos',
        'second': 'segundo',

        'experience': 'Experiencia'
    }
}