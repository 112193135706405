import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { sendAmplitudeData } from '../../utilities/amplitude';
import * as localStorage from '../../utilities/localStorage';

import SmallArrow from '../../components/svgs/SmallArrow';

import { colors } from '../../ui/variables';

import { ListStyle } from './listStyle';

import NotFound from '../notFound/notFound'

const List = () => {
  const [experienceList, setExperienceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);
  const locale = useSelector(state => state.audioGuideReducers.lang);

  const history = useHistory();
  const { id_listagem } = useParams();

  const [viewHistory, setViewHistory] = useState(null);

  useEffect(() => {
    localStorage.get("viewHistory", []).then(data => {
      setViewHistory(data);
    });
  }, [])

  useEffect(() => {
    if (id_listagem) {
      const listFloor = dataAudioguide.filter(item => item.slug === id_listagem);
      if (listFloor.length === 0) {
        //alert('Pavimento não encontrado');
      } else {
        setExperienceList(listFloor[0].experiences);
      }
      setIsLoading(false);
    } else {
      history.push('/');
    }
  }, [dataAudioguide, history, id_listagem]);

  const experienceWasViewed = (idOrCodeNumber) => {
    if(viewHistory){
      return viewHistory.includes(idOrCodeNumber)
    }
    return false;
  }

  const setExperienceUser = item => {
    sendAmplitudeData("Experiencia", item); 
    if (item.code_number !== null && String(item.code_number).length < 3) {
      history.push(`/experiencia/${item.code_number}`);
    } else {
      history.push(`/experiencia/${item.id}`);
    }
  };

  const renderExperienceList = experiences => {
    if (experiences && experiences.length) {
      return (
        <ul className="experience-list">
          {experiences.filter(experience => experience.not_listed == false).map(experience => (
            <li key={experience.id} className={"experience-item" + (experienceWasViewed(experience.code_number || experience.id) ? " viewed" : "")}>
              <button
                type="button"
                className="experience-button"
                onClick={() => setExperienceUser(experience)}
              >
                {experience &&
                experience.code_number &&
                experience.code_number >= 0
                  ? <span className="experience-code">{`${(experience.code_number < 10 ? "0" : "") + experience.code_number} `}</span>
                  : null}
                {experience[`title_${locale}`] &&
                experience[`title_${locale}`].length
                  ? (
                    <span className="experience-name">
                      {experience[`title_${locale}`]}
                    </span>)
                  : null}
                <span className="icon">
                  <SmallArrow color={colors.vividOrange} />
                </span>
              </button>
            </li>
          ))}
        </ul>
      );
    }

    return <NotFound/>;
  }

  return (
    <ListStyle>
      {isLoading
        ? 
        <>
        {/* <h1>Aguarde...</h1> */}
        </>
        : (
          <>
            {renderExperienceList(experienceList)}
          </>
        )
      }
    </ListStyle>
  );
};

export default List;
