import React, { useState, useEffect, useRef } from 'react';
import YouTube from 'react-youtube';

import { VideoContentStyle } from './VideoContentStyle';

// import btnTestPlayYoutube from '../../assets/images/btn_teste_play_youtube.png';

const VideoContent = ({ content, customRef, style, playingId, controlPlay }) => {
  const [playing, setPlaying] = useState(false);
  // const [ready, setReady] = useState(false)
  // const [player, setPlayer] = useState(null);

  const videoRef = useRef();

  useEffect(() => {
    if (playingId !== content.id && playing) {
      videoRef.current.internalPlayer.pauseVideo();
    }
  }, [playingId, content, playing]);

  const getVideoIdByUrl = url => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);

    return (match && match[7].length === 11) ? match[7] : false;
  };

  const onVideoPlay = () => {
    controlPlay();
    setPlaying(true);
  };

  // const testeImg = () => {
  //   console.log("Play-image")
  //   if (!playing && ready) {
  //     videoRef.current.internalPlayer.playVideo();
  //   }
  // };

  const onVideoPause = () => setPlaying(false);

  const onVideoEnd = () => setPlaying(false);

  // const onReady = (event) => {
  //   setReady(true);
  // };

  const opts = {
    playerVars: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: 1
    },
  };

  return (
    <VideoContentStyle className="content" ref={customRef} style={{ ...style }} >
      {content && content.video_title
        ? <h4 className="video-title">{content.video_title}</h4>
        : null
      }
      <p aria-label="A seguir, um vídeo" />

      {/* <img src={btnTestPlayYoutube}
        // className={"capa-video"}
        width="300"
        alt="teste capa youtube"
        onClick={() => testeImg()}
        style={{ display: playing ? 'none' : null }}
      /> */}
      <div className="video-wrapper">
        <YouTube
            ref={videoRef}
            className={"player-youtube"}
            videoId={getVideoIdByUrl(content.url)}
            onPlay={onVideoPlay}
            onPause={onVideoPause} 
            onEnd={onVideoEnd}
            opts={opts}
          />
        {/* <iframe
          frameBorder="0"
          allowFullScreen
          src={"//www.youtube.com/embed/qHn0SJHL6Sk?" +
            "rel=0" +
            "&modestbranding=1" +
            "&autohide=1" +
            "&showinfo=0" +
            "&controls=0"}>
        </iframe> */}


        {/* <div className="video-container" >
            <div className="video-foreground">
                <iframe
                      src="https://www.youtube.com/embed/W0LHTWG-UmQ?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=W0LHTWG-UmQ&mute=1"
                      frameBorder="0" allowFullScreen>
                </iframe>
            </div>             
        </div>                      */}


      </div>
    </VideoContentStyle>
  );
};

export default VideoContent;
