import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import translate from '../../i18n/translate';

import HomeIcon from '../svgs/HomeIcon';
import SmallArrow from '../svgs/SmallArrow';

import { useWindowSize } from '../../utilities/useWindowSize';
import { textLengthByScreenWidth } from '../../utilities/textLengthByScreenWidth';
import { colors } from '../../ui/variables';

import { BreadcrumbStyle } from './BreadcrumbStyle';

const Breadcrumb = ({ show }) => {
  const [layer, setLayer] = useState('');
  const [dataBreadcrumb, setDataBreadcrumb] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);
  const locale = useSelector(state => state.audioGuideReducers.lang);
  const originRoute = useSelector(state => state.audioGuideReducers.originRoute);

  const windowSize = useWindowSize();
  const history = useHistory();
  let location = useLocation();

  const goPath = useCallback(path => {
    history.push(path);
  }, [history]);

  useEffect(() => {
    let locationPathnameSplit = location.pathname.split('/');
    let urlPath = locationPathnameSplit[1];
    let urlId = locationPathnameSplit[2];

    setLayer(urlPath);

    if (urlPath === 'listagem' || urlPath === 'mapa') {
      const listFloorParam = dataAudioguide.filter((item) => item.slug === urlId);

      if (listFloorParam && listFloorParam.length) {
        setDataBreadcrumb(listFloorParam[0]);
      }
    } else if (urlPath === 'experiencia') {
      let pavimentExp = [];

      if (urlId.length < 3) {
        pavimentExp = dataAudioguide.find(
          parent => parent.experiences.some(
            ({ code_number }) => code_number === urlId.replace(/^0+/, '')
          )
        );
      } else {
        pavimentExp = dataAudioguide.find(
          parent => parent.experiences.some(({ id }) => id === urlId)
        );
      }

      if (pavimentExp) {
        let experiencesList = dataAudioguide.map(item => item.experiences);
        let mergedExperiences = [].concat.apply([], experiencesList);
        let experienceParam = [];

        if (urlId.length < 3) {
          experienceParam = mergedExperiences.filter(
            item => item.code_number === urlId.replace(/^0+/, '')
          );
        } else {
          experienceParam = mergedExperiences.filter(item => item.id === urlId);
        }

        if (experienceParam && experienceParam.length) {
          setDataBreadcrumb([{ pavimentExp }, experienceParam[0]]);
        }
      } else {
        history.push('/');
      }
    }

    setIsLoading(false);
  }, [location, dataAudioguide, history, locale, originRoute]);

  const capitalize = str => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  }

  const renderBreadcrumbContent = useMemo(() => {
    if(!dataBreadcrumb){
      return;
    }
    if (!isLoading) {
      if (layer === 'listagem') {
        return (
          <li className="breadcrumb-item">
            <button
              type="button"
              className="breadcrumb-button"
              onClick={() => goPath("/listagem/" + dataBreadcrumb.slug)}
            >
              <span className="arrow-wrapper">
                <SmallArrow color={colors.black} />
              </span>
              {capitalize(dataBreadcrumb[`title_breadcrumb_${locale}`])}
            </button>
          </li>
        );
      } else if (layer === 'experiencia') {
        return (
          <li className="breadcrumb-item">
            <button
              type="button"
              className="breadcrumb-button"
              onClick={() => goPath(
               (originRoute === "map" ? "/mapa/" : "/listagem/") + dataBreadcrumb[0].pavimentExp.slug
              )}
            >
              <span className="arrow-wrapper">
                <SmallArrow color={colors.black} />
              </span>
              {capitalize(dataBreadcrumb[0].pavimentExp[`title_breadcrumb_${locale}`])}
            </button>
            <span className="arrow-wrapper">
              <SmallArrow color={colors.black} />
            </span>
            <button className="breadcrumb-button">
              {textLengthByScreenWidth(capitalize(
                dataBreadcrumb[1][`title_${locale}`],
                windowSize.width
              ))}
            </button>
          </li>
        );
      } else if (layer === 'mapa') {
        return (
          <li className="breadcrumb-item">
            <span className="arrow-wrapper">
              <SmallArrow color={colors.black} />
            </span>
            <button
              type="button"
              className="breadcrumb-button"
            >
              {dataBreadcrumb ? capitalize(dataBreadcrumb[`title_breadcrumb_${locale}`]) : ''}
            </button>
          </li>
        );
      } else if (layer === 'satisfacao') {
        return (
          <li className="breadcrumb-item">
            <span className="arrow-wrapper">
              <SmallArrow color={colors.black} />
            </span>
            <button
              type="button"
              className="breadcrumb-button"
            >
              {translate('feedback')}
            </button>
          </li>
        );
      }
    }
  }, [isLoading, layer, dataBreadcrumb, locale, goPath, originRoute, windowSize.width]);

  return (
    <BreadcrumbStyle className={show ? 'show' : ''}>
      <li className="breadcrumb-item">
        {show ?
          <button
            type="button"
            className="breadcrumb-button home-button"
            onClick={() => {
              if(originRoute === "map" || layer === "mapa" ){
                goPath('/mapa')
              }else{
                goPath('/')}
              }
            } 
          >
            <HomeIcon />
          </button>
        : null}
      </li>
      {renderBreadcrumbContent}
    </BreadcrumbStyle>
  );
};

export default Breadcrumb;
