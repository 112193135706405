import {LOCALES} from '../locales'

export default {
    [LOCALES.PORTUGUESE]:{
        'nameMuseum': 'Museu da Língua Portuguesa',
        'titleFeedback': 'Avalie o App',

        'code': 'Código', 
        'list': 'Índice',
        'map': 'Mapa',

        'pageNotFound': 'Página não encontrada',

        'VisitOurSite': 'Visite nosso site',

        'hello': 'Olá',
        'descriptionFeedbak' : 'Qual é a sua avaliação para a experiência com este aplicativo?',
        'placeholderTxtBoxFeedback' : 'Deixe aqui comentários e sugestões',
        'submit': 'Enviar',
        'back' : 'Voltar',

        'readMore':'Leia mais',
        'readLess':'Leia menos',

        'btnEnterCode' : 'Acessar',
        'msgQRCode':'Digite o código da instalação ou use a câmera do seu celular para escanear o QR Code',
        'msgQRCodeError':'Código da instalação não encontrado', 
        'allowCam':'permitir acesso à câmera.',

        'thanks':'Obrigado',
        'continue': 'Continuar',

        'contentNotAvailable' : 'Este conteúdo não está disponível neste idioma',

        'feedback': 'Satisfação',

        'collectMenu': 'Recolher menu',

        'cardinal0': ' ',
        'cardinal1': 'Primeiro',
        'cardinal2': 'Segundo',
        'cardinal3': 'Terceiro',

        'floor': 'Pavimento',

        'playAudio': 'Tocar áudio',
        'pauseAudio': 'Pausar áudio',

        'and': 'e',
        'minutes': 'minutos',
        'minute': 'minuto',
        'seconds': 'segundos',
        'second': 'segundo',

        'experience': 'Experiência'
    }
}