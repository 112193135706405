import styled from 'styled-components';

import { breakpoints, colors, fontFamily, functions, grid, zIndex } from '../../ui/variables';

export const QrCodeStyle = styled.div`
  padding-top: ${
    functions.responsive.vh(
      10,
      12,
      breakpoints.iphone.height,
      breakpoints.iphoneSe.height
    )
  };

  @media (min-height: ${breakpoints.iphoneSe.height}px) {
    padding-top: 12px;
  }

  @media (min-height: ${breakpoints.iphoneX.height}px) {
    padding-top: 30px;
  }

  .code-title {
    color: ${colors.white};
    font-size: ${
      functions.responsive.vh(
        20,
        24,
        breakpoints.iphone.height,
        breakpoints.iphoneSe.height
      )
    };
    letter-spacing: -0.36px;
    line-height: 26px;
    margin-bottom: ${
      functions.responsive.vh(
        14,
        18,
        breakpoints.iphone.height,
        breakpoints.iphoneSe.height
      )
    };
    text-align: center;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      font-size: 24px;
      margin-bottom: 18px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  .code-form {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: ${
      functions.responsive.vh(
        16,
        20,
        breakpoints.iphone.height,
        breakpoints.iphoneSe.height
      )
    };
    width: 100%;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      margin-bottom: 20px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      margin-bottom: 24px;
    }

    &.valid {
      .code-input {
        border: solid 1px ${colors.vividOrange};
      }

      .code-submit {
        background-color: ${colors.vividOrange};
      }
    }

    .code-input {
      ${fontFamily.sohneDreiviertelfett};
      background: transparent;
      border: solid 1px ${colors.darkGray};
      color: ${colors.white};
      display: block;
      font-size: 28px;
      letter-spacing: 15.98px;
      line-height: 44px;
      margin-right: 8px;
      text-align: center;
      text-indent: 15.98px;
      width: 116px;
      padding-top: 1px;

      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: ${colors.darkGray};
        text-align: center;
      }
    }

    .code-submit {
      ${fontFamily.sohneDreiviertelfett};
      background-color: ${colors.darkGray};
      color: ${colors.white};
      font-size: 18px;
      letter-spacing: -0.36px;
      line-height: 22px;
      margin-left: 8px;
      padding: 12px 0;
      width: 116px;

      &:disabled {
        color: ${colors.veryDarkGray};
        cursor: not-allowed;
      }
    }
  }

  .code-instruction {
    ${fontFamily.sohneKraftig}
    font-size: 12px;
    line-height: 16px;
    margin: 0 auto 20px;
    max-width: ${breakpoints.iphoneX.width}px;
    padding: 0 ${grid.minOuter}px;
    text-align: center;

    @media (min-height: ${breakpoints.iphone8.height}px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      margin: 0 auto 30px;
    }

    &.code-not-found{
      color: ${colors.vividRed}  
    }
  }

  .qr-code-wrapper {
    box-sizing: content-box;
    height: calc(100vw - ${2 * grid.minOuter}px);
    margin: 0 auto;
    max-height: ${
      functions.responsive.vh(
        180,
        248,
        breakpoints.iphone.height,
        breakpoints.iphoneSe.height
      )
    };
    max-width: ${
      functions.responsive.vh(
        180,
        248,
        breakpoints.iphone.height,
        breakpoints.iphoneSe.height
      )
    };
    padding: 0 ${grid.minOuter}px;
    position: relative;

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      max-height: ${
        functions.responsive.vh(
          248,
          414,
          breakpoints.iphoneSe.height,
          breakpoints.iphoneX.height
        )
      };
      max-width: ${
        functions.responsive.vh(
          248,
          414,
          breakpoints.iphoneSe.height,
          breakpoints.iphoneX.height
        )
      };
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      max-height: 414px;
      max-width: 414px;
    }

    & > section {
      & > section {
        & > div {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }

    .code-target {
      ${zIndex.hills};
      bottom: 0;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        height: 100%;
      }

      .allow-webcan {
        ${fontFamily.sohneKraftig};
        background: transparent;
        display: block;
        font-size: 14px;
        height: 100%;
        left: 0;
        letter-spacing: -0.24px;
        line-height: 17px;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
`;
