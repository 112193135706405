import styled from 'styled-components';

import {
  breakpoints,
  colors,
  fontFamily,
  zIndex
} from '../../ui/variables.js';

export const MenuStyle = styled.div`
  ${zIndex.space};
  background-color: ${colors.veryLightGray};
  max-width: ${breakpoints.ipad.width}px;
  min-height: 100vh;
  position: absolute;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  transition: left 2500ms linear;
  width: 100%;

  .menu-head {
    background-color: ${colors.black};
    padding: 36px;
    width: 100%;

    .logo-mlp {
      svg {
        width: 205px;
      }
    }

    .hide-menu {
      display: block;
      height: 24px;
      position: absolute;
      right: 36px;
      top: 46px;
      width: 24px;
    }
  }

  .menu-cont {
    background-color: ${colors.veryLightGray};
    padding: 28px 36px 0;
    width: 100%;

    .feedback-link {
      ${fontFamily.sohneFett}
      border-bottom: solid 4px ${colors.black};
      color: ${colors.black};
      display: block;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 22px;
      padding-bottom: 12px;
      position: relative;
      text-align: left;
      width: 100%;

      .icon {
        height: 14px;
        position: absolute;
        right: 0;
        top: 8px;
        transform: translateY(calc(-50% - #{12 / 2}px));
        width: 14px;
      }
    }

    .app-rating {
      .rating-stars {
        display: inline-block;
        margin-right: 16px;

        .star-wrapper {
          display: inline-block;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }

          svg {
            height: 20px;
            width: auto;
          }
        }
      }

      .rating-amount {
        ${fontFamily.sohneLeicht};
        color: ${colors.veryDarkGray};
        font-size: 21px;
        letter-spacing: -0.32px;
        line-height: 27px;
      }
    }
  }

  .menu-foot {
    background-color: ${colors.veryLightGray};
    bottom: 0;
    left: 0;
    padding: 0 36px 54px;
    position: absolute;
    width: 100%;

    .our-site-label {
      ${fontFamily.sohneKraftig};
      color: ${colors.veryDarkGray};
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 8px;
      text-align: center;

      @media (min-width: ${breakpoints.iphoneX.width}px) {
        font-size: 16px;
      }
    }

    .our-site-link {
      ${fontFamily.sohneDreiviertelfett};
      color: ${colors.black};
      font-size: 16px;
      letter-spacing: 0.36px;
      line-height: 23px;
      margin-bottom: 24px;
      text-align: center;

      @media (min-width: ${breakpoints.iphoneX.width}px) {
        font-size: 18px;
      }
    }

    .social-links {
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width:320px;
      margin:0 auto;

      .social-item {
        display: block;
        width: 44px;

        .social-link {
          display: block;
          line-height: 0;
        }
      }
    }
  }
`;
