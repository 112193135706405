import styled from 'styled-components';

import { colors, fontFamily, grid, mixins } from '../../ui/variables.js';


export const ListStyle = styled.div`
  padding-top: 40px;

  .map-pavement-wrapper {
    margin-bottom: 32px;
    padding: 22px ${grid.minOuter}px 0;

    .map-image {
      ${mixins.marginLeftRightAuto};
      display: block;
      height: auto;
      width: 100%;
    }
  }

  .experience-list {
    padding: 0 ${grid.minOuter}px;

    .experience-item {
      border-bottom: solid 1px ${colors.veryDarkGray};

      .experience-button {
        display: block;
        padding: 18px 0 18px 24px;
        position: relative;
        text-align: left;
        width: 100%;

        .experience-code,
        .experience-name {
          ${fontFamily.sohneDreiviertelfett};
          font-size: 16px;
          letter-spacing: -0.24px;
          line-height: 21px;
        }

        .experience-code {
          color: ${colors.vividOrange};
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .experience-name {
          color: ${colors.white};
        }

        .icon {
          height: 16px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
        }
      }

      &.viewed {
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 469.333 469.333'><g><path d='M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z' fill='%23555'/><path d='M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160,c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333,c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667,S293.547,341.333,234.667,341.333z' fill='%23555'/></g></svg>") no-repeat 1px 36px;
        background-size: 18px 18px;
      }
    }
  }
`;
