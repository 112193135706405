import React from "react";

import { colors } from '../../ui/variables';

const FacebookIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <title>Facebook</title>
    <g fill={color || colors.black}>
      <path d="M19,0a19,19,0,0,0,0,38h.33V23.21H15.25V18.45h4.08V15c0-4.06,2.48-6.27,6.1-6.27a32.1,32.1,0,0,1,3.67.18v4.25H26.6c-2,0-2.35.93-2.35,2.3v3H29l-.61,4.76h-4.1V37.26A19,19,0,0,0,19,0Z" />
    </g>
  </svg>
);

export default FacebookIcon;
