import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import DefaultArrow from '../svgs/DefaultArrow';
import FacebookIcon from '../svgs/FacebookIcon';
import InstagramIcon from '../svgs/InstagramIcon';
import LogoMlp from '../svgs/LogoMlp';
import SmallArrow from '../svgs/SmallArrow';
import StarIcon from '../svgs/StarIcon';
import TwitterIcon from '../svgs/TwitterIcon';
import YoutubeIcon from '../svgs/YoutubeIcon';

import { MenuStyle } from './MenuStyle.js';

import { colors } from "../../ui/variables";

import translate from '../../i18n/translate';

const Menu = ({ show, switchVisibility, intl, style, nodeRef }) => {
  const [appRating, setAppRating] = useState(4);
  const [ratingAmount, setRatingAmount] = useState(28);

  const averageRate = useSelector(state => state.audioGuideReducers.averageRate);

  const history = useHistory();

  const hideMenuRef = useRef();

  const renderAppRating = () => (
    <button
      type="button"
      className="app-rating"
      onClick={onClickFeedbackLink}
      aria-hidden="true"
    >
      <span className="rating-stars">
        {Array.from(Array(5)).map((star, index) => (
          <span key={index} className="star-wrapper">
            <StarIcon isFilled={index < appRating} />
          </span>
        ))}
      </span>
      <span className="rating-amount">{`(${ratingAmount})`}</span>
    </button>
  );

  const onClickFeedbackLink = () => {
    switchVisibility();
    history.push('/satisfacao');
  };

  const onClickLogoMlp = () => {
    switchVisibility();
    history.push('/');
  };

  useEffect(() => {
   setAppRating(averageRate.average.toFixed())
   setRatingAmount(averageRate.total)
  }, [averageRate])

  useEffect(() => { 
    if(hideMenuRef) hideMenuRef.current.focus()
  })

  return (
      <MenuStyle aria-hidden={!show} style={style} ref={nodeRef}>
        <div className="menu-head">
          <button className="logo-mlp" onClick={onClickLogoMlp}>
            <LogoMlp />
          </button>
          <button ref={hideMenuRef} className="hide-menu" aria-label={intl.formatMessage({ id: 'collectMenu' })} onClick={switchVisibility}>
            <DefaultArrow />
          </button>
        </div>
        <div className="menu-cont">
          <button
            type="button"
            className="feedback-link"
            onClick={onClickFeedbackLink}
          >
            {translate("titleFeedback")}
            <span className="icon">
              <SmallArrow color={colors.black} />
            </span>
          </button>
          {renderAppRating()}
        </div>
        <div className="menu-foot">
          <p className="our-site-label">{translate("VisitOurSite")}</p>
          <p className="our-site-link">
            <a
              href="https://www.museudalinguaportuguesa.org.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              museudalinguaportuguesa.org.br
          </a>
          </p>
          <ul className="social-links">
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.facebook.com/MuseuDaLinguaPortuguesa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.instagram.com/museudalinguaportuguesa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://www.youtube.com/channel/UCDyct6F210UrUWptJ22DkNw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </li>
            <li className="social-item">
              <a
                className="social-link"
                href="https://twitter.com/MuseudaLingua"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            </li>
          </ul>
        </div>
      </MenuStyle>
  );
};

export default injectIntl(Menu);
