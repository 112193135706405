import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../actions/actions';

import translate from '../../i18n/translate';

import { NavigationBarStyle } from './NavigationBarStyle.js';

const NavigationBar = ({ currentLanguage }) => {
  const history = useHistory();
  const location = useLocation();
  const [showNavigationBar, setShowNavigationBar] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('list');

  const [urlPath, setUrlPath] = useState('');
  const [urlId, setUrlId] = useState('');
  const [navPosition, setNavPosition] = useState('none');
  const [currentPos, setPosition] = useState('left');

  const dataAudioguide = useSelector(
    state => state.audioGuideReducers.dataAudioguide
  );
  const originRoute = useSelector(
    state => state.audioGuideReducers.originRoute
  );

  const dispatch = useDispatch();
  const setOriginRoute = (routeChoice) => dispatch(actions.setOriginRoute(routeChoice))

  const onClickNavigationButton = event => {
    let routeChoice = event.currentTarget.value
    let pavimentExp = []
    if (typeof routeChoice === 'string') {
      if (routeChoice === "mapa") {
        setOriginRoute('map')
        if (urlPath === "experiencia") {
          if (urlId.length < 3) {
            pavimentExp = dataAudioguide.find(
              parent => parent.experiences.some(({ code_number }) => code_number === urlId.replace(/^0+/, ''))
            );
            history.push(`/${routeChoice}/${pavimentExp.slug}`)
          } else {
            pavimentExp = dataAudioguide.find(
              parent => parent.experiences.some(({ id }) => id === urlId)
            );
            history.push(`/${routeChoice}/${pavimentExp.slug}`)
          }
        }
        else if (urlPath === "mapa") {
          if (urlPath === routeChoice && !urlId) return
          history.push(`/${routeChoice}`)
        }
        else {
          if (urlId) {
            history.push(`/${routeChoice}/${urlId}`)
          } else {
            history.push(`/${routeChoice}`)
          }
        }
      } else if (routeChoice === "") {
        setOriginRoute('index')
        if (urlPath === routeChoice && !urlId) return
        else if (urlPath === "experiencia") {
          history.push(`/${routeChoice}`)
        } else if (urlPath === "listagem") {
          if (urlPath === routeChoice && !urlId) return
          history.push(`/${routeChoice}`)
        } else {
          if (urlId) {
            history.push(`/listagem/${urlId}`)
          } else {
            history.push(`/${routeChoice}`)
          }
        }
      }
      else if (routeChoice === "codigo") {
        setOriginRoute('code')
        history.push(`/${routeChoice}`)
      }
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname.split('/')[1];
    setCurrentUrl(currentUrl);
    setShowNavigationBar(
      currentUrl === 'listagem' ||
      currentUrl === 'codigo' ||
      currentUrl === 'mapa' ||
      currentUrl === 'experiencia' ||
      currentUrl === ''
    );

    let locationPathnameSplit = location.pathname.split('/');
    setUrlPath(locationPathnameSplit[1]);
    setUrlId(locationPathnameSplit[2])
  }, [location]);

  useEffect(() => {
    let position = '';
    if (originRoute === 'code') {
      position = 'left';
    } else if (
      originRoute === 'index' ||
      currentUrl === '' ||
      currentUrl === '/'
    ) {
      position = 'center';
    } else if (originRoute === 'map' || currentUrl === 'mapa') {
      position = 'right';
    } else {
      position = 'none';
    }

    setPosition(position);
    if (currentLanguage) {
      position = `${position} locale-${currentLanguage}`;
    }

    setNavPosition(position);
  }, [originRoute, currentUrl, currentLanguage]);

  return (
    showNavigationBar
      ? (
        <NavigationBarStyle>
          <ul className={`navigation-list ${navPosition}`}>
            <li className="navigation-item">
              <button
                type="button"
                className={`navigation-button ${currentPos === "left" ? "enabled" : "a" }`}
                value="codigo"
                onClick={onClickNavigationButton}
              >
                <span className="text-cont">{translate('code')}</span>
              </button>
            </li>
            <li className="navigation-item">
              <button
                type="button"
                className={`navigation-button ${currentPos === "center" ? "enabled" : "b" }`}
                value=""
                onClick={onClickNavigationButton}
              >
                <span className="text-cont">{translate('list')}</span>
              </button>
            </li>
            <li className="navigation-item">
              <button
                type="button"
                className={`navigation-button ${currentPos === "right" ? "enabled" : "c" }`}
                value="mapa"
                onClick={onClickNavigationButton}
              >
                <span className="text-cont">{translate('map')}</span>
              </button>
            </li>
          </ul>
        </NavigationBarStyle>
      )
      : null
  );
}

export default NavigationBar;
