import React, { useState, useEffect, useCallback, createRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../actions/actions';
import { Transition } from 'react-transition-group';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Menu from '../Menu/Menu';
import BurguerIcon from '../svgs/BurguerIcon';
import SmallArrow from '../svgs/SmallArrow';

import * as localStorage from '../../utilities/localStorage';
import { LOCALES } from '../../i18n';

import { HeaderStyle } from './HeaderStyle.js';

const Header = ({ selectLanguage }) => {
  const [locale, setLocale] = useState();
  const [showLanguageList, setShowLanguageList] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);

  let location = useLocation();

  const localeDefault = useSelector(state => state.audioGuideReducers.lang);

  const dispatch = useDispatch()
  // const setDisplayMenu = (showMenu) => dispatch(actions.setDisplayMenu(showMenu))
  const setDisplayContent = (showContent) => dispatch(actions.setDisplayContent(showContent))

  useEffect(() => {
    if (location && location.pathname) {
      let pathname = location.pathname;
      if (
        pathname.length > 1 &&
        pathname.slice(pathname.length - 1) === '/'
      ) {
        pathname = pathname.slice(0, -1);
      }

      setShowBreadcrumb(
        !(
          pathname === '/' ||
          pathname === '/mapa' ||
          pathname === '/codigo'
        )
      );
    }
  }, [location]);

  useEffect(() => {
    localStorage.get('lang').then(data => {
      if (data !== undefined) {
        setLocale(data);
      } else {
        setLocale(localeDefault);
      }
    });
  }, [locale, localeDefault]);

  const onClickWindow = useCallback(() => {
    setShowLanguageList(false)
  }, [])

  useEffect(() => {
    if (showLanguageList) {
      window.addEventListener('click', onClickWindow);
    } else {
      window.removeEventListener('click', onClickWindow);
    }
  }, [onClickWindow, showLanguageList])


  useEffect(() => {
    // setDisplayMenu()
  }, [showMenu])


  const getLanguageName = language => {
    switch (language) {
      case LOCALES.ENGLISH:
        return 'English';

      case LOCALES.SPANISH:
        return 'Español';

      case LOCALES.PORTUGUESE:
        return 'Português';

      case LOCALES.LIBRAS:
        return 'Libras';

      default:
        return '';
    }
  };

  const switchSelectedLanguageVisibility = e => {
    e.stopPropagation();
    setShowLanguageList(!showLanguageList);
  };

  const switchMenuVisibility = () => {
    setShowMenu(!showMenu);
  };

  const handleSelectLanguage = chosenLanguage => {
    selectLanguage(chosenLanguage);
    setShowLanguageList(false);
  };


  const duration = 500;

  const defaultStyle = {
    transition: `left ${duration}ms linear`,
    left: '-55%'
  };

  var nodeRef = createRef()

  const onMenuEnter = () => nodeRef.current.style.left = '-55%';

  const onMenuEntering = () => nodeRef.current.style.left = '50%';

  const onMenuEntered = () => setDisplayContent(false);

  const onMenuExiting = () => {
    nodeRef.current.style.left = '-55%';
    setDisplayContent(true);
  }

  return (
    <HeaderStyle>
      <div className="header-wrapper">
        <div className="header-cont">
          <button
            className="burguer-icon-wrapper"
            onClick={switchMenuVisibility}
          >
            <BurguerIcon />
          </button>
          <button
            aria-label="seleção de idiomas"
            className="selected-language"
            onClick={switchSelectedLanguageVisibility}
          >
            {getLanguageName(locale)}
            <span className={`icon-wrapper${showLanguageList? ' active' : ''}`}>
              <SmallArrow />
            </span>
          </button>
        </div>
        <div className={`breadcrumb-wrapper${showBreadcrumb ? ' show' : ''}`}>
          <Breadcrumb show={showBreadcrumb} />
        </div>
        <div className="language-wrapper">
          <LanguageSelector
            currentLanguage={locale}
            show={showLanguageList}
            selectLanguage={handleSelectLanguage}
            floating
          />
        </div>
      </div>

      <Transition
        nodeRef={nodeRef}
        in={showMenu}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={{
          appear: duration,
          enter: duration,
          exit: duration,
        }}

        onEnter={onMenuEnter}
        onEntering={onMenuEntering}
        onEntered={onMenuEntered}
        onExiting={onMenuExiting}
      // onExited={onMenuExiting}
      >
        {state => {
          return (
            <Menu
              nodeRef={nodeRef}
              style={{
                ...defaultStyle,
                // ...transitionStyles[state]
              }}
              show={showMenu}
              switchVisibility={switchMenuVisibility}
            />
          )
        }}
      </Transition>


    </HeaderStyle>
  );
};

export default Header;
