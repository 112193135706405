import {LOCALES} from '../locales'

export default {
    [LOCALES.ENGLISH]:{
        'nameMuseum': 'Museu da Língua Portuguesa',
        'titleFeedback': 'Rate the app',

        'code': 'Code', 
        'list': 'Index',
        'map': 'Map',

        'pageNotFound': 'Page not found',

        'VisitOurSite': 'Visit our website',

        'hello': 'Hello', 
        'descriptionFeedbak' : 'How would you rate your experience with this app?',
        'placeholderTxtBoxFeedback' : 'Leave your comments and suggestions here',
        'submit': 'Submit',
        'back' : 'back',

        'readMore':'Read more',
        'readLess':'Read less',

        'btnEnterCode' : 'Enter',
        'msgQRCode': "Enter the installation code or use your mobile phone's camera to scan the QR Code",
        'msgQRCodeError':'Installation code not found', 
        'allowCam':'Allow access to camera',

        'thanks':'Thanks',
        'continue': 'Continue',

        'contentNotAvailable' : 'Content not available in English',

        'feedback': 'Feedback',

        'collectMenu': 'Collect Menu',

        'cardinal0': ' ',
        'cardinal1': 'First',
        'cardinal2': 'Second',
        'cardinal3': 'Third',

        'floor': 'Floor',

        'playAudio': 'Play audio',
        'pauseAudio': 'Pause audio',

        'and': 'and',
        'minutes': 'minutes',
        'minute': 'minute',
        'seconds': 'seconds',
        'second': 'second',

        'experience': 'Experience'
    }
}