import { css } from 'styled-components';

export const colors = {
  black: '#000',
  veryDarkGray: '#5a5a5a',
  darkGray: '#A6A5A5',
  darkGray25: '#80808040',
  veryLightGray: '#f0f0f0',
  white: '#fff',
  limeGreen: '#00a54e',
  strongBlue: '#0c73bb',
  vividOrange: '#F59307',
  vividRed: '#ed2024',
};

export const zIndex = {
  moon: 'z-index: 9999',
  space: 'z-index: 999',
  sky: 'z-index: 99',
  hills: 'z-index: 9',
  floor: 'z-index: 0',
  underground: 'z-index: -9',
  bedrock: 'z-index: -99'
};

export const breakpoints = {
  iphone: {
    height: 480,
    width: 320
  },
  iphoneSe: {
    height: 568,
    width: 320
  },
  iphone8: {
    height: 667,
    width: 375
  },
  iphonePlus: {
    height: 736,
    width: 414
  },
  iphoneX: {
    height: 812,
    width: 414
  },
  iphoneXR: {
    height: 896,
    width: 414
  },
  ipad: {
    height: 1024,
    width: 768
  }
};

export const grid = {
  minOuter: 28
}

export const fontFamily = {
  sohneKraftig: (kursiv) => css`${`
    font-family: Sohne;
    font-weight: 500;
    font-style: ${kursiv === 'italic' || kursiv === 'kursiv' ? 'italic' : 'normal'};
  `}`,
  sohneLeicht: (kursiv) => css`${`
    font-family: Sohne;
    font-weight: 300;
    font-style: ${kursiv === 'italic' || kursiv === 'kursiv' ? 'italic' : 'normal'};
  `}`,
  sohneDreiviertelfett: (kursiv) => css`${`
    font-family: Sohne;
    font-weight: bold;
    font-style: ${kursiv === 'italic' || kursiv === 'kursiv' ? 'italic' : 'normal'};
  `}`,
  sohneFett: (kursiv) => css`${`
    font-family: Sohne;
    font-weight: 900;
    font-style: ${kursiv === 'italic' || kursiv === 'kursiv' ? 'italic' : 'normal'};
  `}`
};

export const mixins = {
  marginLeftRightAuto: css`${`
    margin-left: auto;
    margin-right: auto;
  `}`
};

const responsive = (orientation, minV, maxV, minS, maxS) => {
  const a = (maxV - minV) / (maxS - minS);
  const b = (maxS * minV - minS * maxV) / (maxS - minS);

  return `calc(${a * 100}${orientation} + ${b}px)`;
}

export const functions = {
  responsive: {
    vh: (
      minValue,
      maxValue,
      minHeight = breakpoints.iphone.height,
      maxHeight = breakpoints.ipad.height
    ) => responsive('vh', minValue, maxValue, minHeight, maxHeight),
    vw: (
      minValue,
      maxValue,
      minWidth = breakpoints.iphone.width,
      maxWidth = breakpoints.ipad.width
    ) => responsive('vw', minValue, maxValue, minWidth, maxWidth),
  }


}

