import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MapPresentationStyle } from './mapPresentationStyle';

const MapPresentation = () => {
  const [map, setMap] = useState({});
  const [experiencePoints, setExperiencePoints] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);
  const locale = useSelector(state => state.audioGuideReducers.lang);

  const { id_map } = useParams();

  useEffect(() => {
    if (id_map) {
      dataAudioguide.map(item => item.slug === id_map
        ? (
          setMap(item.map),
          setExperiencePoints(item.experiences)
        ) : null)
    }
  }, [id_map, dataAudioguide, experiencePoints]);

  useEffect(() => {
    if (experiencePoints.length > 0) {
      setIsLoading(false)
    }
  }, [experiencePoints]);

  return (
    <MapPresentationStyle>
      <section className="map-container">
        <div className="map-image-wrapper">
          {map && map.url && map.url.length
            ? (
              <img
                className="image-map"
                src={map.url}
                alt="mapa"
              />
            ) : null}
          {isLoading ? <></>
            : (
              <ul className="map-point-list">
                {experiencePoints.filter(experience => experience.not_listed == false).map((experience, index) => {
                  if (experience && experience.map_coords && experience.map_coords.length) {
                    return experience.map_coords.map(coords => {
                      const positionX = Math.floor(coords.x / map.width * 100);
                      const positionY = Math.floor(coords.y / map.height * 100);
                      return (
                        <li
                          key={coords.id}
                          className={`map-point text-${coords.text_align ? coords.text_align : 'centralized'}`}
                          style={{
                            left: `${positionX}%`,
                            top: `${positionY}%`
                          }}
                        >
                          <Link
                            className="point-link"
                            to={`/experiencia/${experience.code_number}`}
                          >
                            <span className="point-code">{`${experience.code_number} `}</span>
                            <span className="point-name">{experience[`title_${locale}`]}</span>
                          </Link>
                        </li>
                      );
                    });
                  }

                  return null;
                })}
              </ul>
            )}
        </div>
      </section>
    </MapPresentationStyle>
  );
};

export default MapPresentation;
