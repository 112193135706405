import styled from 'styled-components';

import { LOCALES } from '../../i18n';
import { breakpoints, colors, fontFamily, functions, zIndex } from '../../ui/variables.js';


export const LanguageSelectorStyle = styled.div`
  &.floating {
    ${zIndex.hills};
    background-color: ${colors.white};
    color: ${colors.black};
    display: inline-block;
    min-width: 320px;
    padding: 30px 39px 30px 39px;
    position: absolute;
    right: 0;
    top: -320px;
    margin-right: 15px;
    margin-top:85px;
    transition: top 300ms;
    transform: scale(1);
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 10px 4px 4px rgba(0, 0, 0, 0.2);

    @media (max-height: ${breakpoints.iphoneSe.height}px) {
      min-width: 280px;
      padding: 30px 20px;
    }

    &.show {
      top: -20px;

      @media (min-height: ${breakpoints.iphoneX.height}px) {
        top: 6px;
      }
    }

    &.delay-animation {
      transition: top 300ms 600ms;
    }
  }

  .language-list {
    .language-item {
      margin-bottom: 4px;

      .item-button {
        &.active,
        &:hover {
          &.language-${LOCALES.PORTUGUESE} {
            color: ${colors.vividOrange};
          }

          &.language-${LOCALES.LIBRAS} {
            color: ${colors.vividOrange};
          }

          &.language-${LOCALES.SPANISH} {
            color: ${colors.vividOrange};
          }

          &.language-${LOCALES.ENGLISH} {
            color: ${colors.vividOrange};
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-button {
        ${fontFamily.sohneFett};
        display: inline-block;
        font-size: 40px;
        line-height: 58px;
        position: relative;
        transition: border-bottom 300ms, border-top 300ms;
        
        &.with-icon {
          position: relative;

          .icon {
            display: inline-block;
            height: 22px;
            position: absolute;
            right: -44px;
            top: calc(50% + 4px);
            transform: translateY(-50%);
            width: 34px;
          }
        }
      }
    }
  }
`;
