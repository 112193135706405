import React, { useState, useEffect, createRef, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import SlideShow from '../../components/Slideshow/Slideshow';
import AudioContent from '../../components/AudioContent/AudioContent';
import TextContent from '../../components/TextContent/TextContent';
import VideoContent from '../../components/VideoContent/VideoContent';

import { ExperienceStyle } from './experienceStyle';

import { injectIntl } from 'react-intl';

import * as localStorage from '../../utilities/localStorage';

import NotFound from '../notFound/notFound'

const Experience = ({ intl }) => {
  const [experience, setExperience] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [playingId, setPlayingId] = useState('');
  const contentDelay = 250;
  const numberRef = useRef()
  const titleRef = useRef()

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);
  const locale = useSelector(state => state.audioGuideReducers.lang);

  let [showTitle, setShowTitle] = useState(false);
  let [showCodeNumber, setShowCodeNumber] = useState(false);
  let [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(false)
    setTimeout(() => setShowTitle(true), 500);
    setTimeout(() => setShowCodeNumber(true), 1000);
    setTimeout(() => setShowContent(true), 1000);
  }, [locale])

  const timeoutTransition = {
    title: 600,
    codeNumber: 600,
    text: 600,
    audio: 600,
    video: 600
  }
  const history = useHistory();
  let { id_experiencia } = useParams();

  useEffect(() => {
    localStorage.get("viewHistory", []).then(data => {
      data.push(experience.code_number || experience.id)
      localStorage.set("viewHistory", data);
    });
  }, [experience])

  useEffect(() => {
    let idExpUrl = id_experiencia.replace(/^0+/, '');

    if (idExpUrl) {
      let experiencesList = dataAudioguide.map(item => item.experiences);
      let mergedExperiences = [].concat.apply([], experiencesList);
      let experience = [];

      if (idExpUrl.length < 3) {
        experience = mergedExperiences.filter(item => item.code_number === idExpUrl);
      } else {
        experience = mergedExperiences.filter(item => item.id === idExpUrl);
      }

      if (experience[0]) {
        setExperience(experience[0]);
        setLoading(false);
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }, [dataAudioguide, history, id_experiencia]);

  const playContent = id => setPlayingId(id);

  const renderExperienceContent = (content, index) => {
    var nodeRef = createRef()
    if (
      content &&
      content.id &&
      content.type &&
      content.type.length
    ) {
      switch (content.type) {
        case 'text':
          if (content.text && content.text.length) {
            return (
              <CSSTransition nodeRef={nodeRef} in={showContent} timeout={index * contentDelay} classNames="transition-content" key={content.id}>
                <TextContent
                  key={content.id}
                  customRef={nodeRef}
                  content={content.text}
                  style={{ transitionDelay: (index * contentDelay) + 'ms' }}
                />
              </CSSTransition>
            );
          }

          return null;

        case 'audio':
          if (content.url && content.url.length) {
            if(content.url.substring(0, 4)!=="http"){
              content.url = "https://"+content.url;
            }
            return (
              <CSSTransition nodeRef={nodeRef} in={showContent} timeout={index * contentDelay} classNames="transition-content" key={content.id}>
                <AudioContent
                  key={content.id}
                  customRef={nodeRef}
                  content={content}
                  style={{ transitionDelay: (index * contentDelay) + 'ms' }}
                  playingId={playingId}
                  controlPlay={() => playContent(content.id)}
                />
              </CSSTransition>
            );
          }

          return null;

        case 'video':
          return (
            <CSSTransition nodeRef={nodeRef} in={showContent} timeout={index * contentDelay} classNames="transition-content" key={content.id}>
              <VideoContent
                key={content.id}
                customRef={nodeRef}
                content={content}
                style={{ transitionDelay: (index * contentDelay) + 'ms' }}
                controlPlay={() => playContent(content.id)}
                playingId={playingId}
              />
            </CSSTransition>
          );

        default:
          return null;
      }
    }
  };

  return (
    <ExperienceStyle>
      {isLoading
        ? (<></>) :
        (
          <>
            <SlideShow content={experience.slideshow} />
            <div className="experience-content-wrapper">
              <CSSTransition nodeRef={titleRef} in={showTitle} timeout={timeoutTransition.title} classNames="transition-title">
                <h2 className="experience-title" ref={titleRef} aria-label={experience.title_pt}>
                  {experience[`title_${locale}`]}
                </h2>
              </CSSTransition>

              {experience && experience.code_number
                ? (
                  <CSSTransition nodeRef={numberRef} in={showCodeNumber} timeout={timeoutTransition.codeNumber} classNames="transition-code-number" key={experience.code_number}>
                    <p ref={numberRef} className="experience-code" aria-label={intl.formatMessage({ id: 'experience' }) + ' ' + experience.code_number}>
                      {experience.code_number}
                    </p>
                  </CSSTransition>
                ) : null}

              {
                experience[`content_${locale}`].length === 0 ? <NotFound /> :
                  experience[`content_${locale}`].map((content, index) => {
                    return renderExperienceContent(content, index);
                  })
              }

            </div>
          </>
        )}
    </ExperienceStyle>
  );
};

export default injectIntl(Experience);
