import styled from 'styled-components';

import { breakpoints, colors, fontFamily, grid } from '../../ui/variables.js';

export const FeedbackStyle = styled.div`
  padding-top: 64px;

  .feedback-title {
    ${fontFamily.sohneLeicht};
    font-size: 36px;
    letter-spacing: -0.54px;
    line-height: 45px;
    margin-bottom: 16px;
    text-align: center;
  }

  .feedback-description {
    ${fontFamily.sohneKraftig};
    font-size: 16px;
    letter-spacing: -0.24px;
    line-height: 21px;
    margin-bottom: 36px;
  }

  .feedback-title,
  .feedback-description {
    color: ${colors.darkGray};
    padding: 0 44px;
    text-align: center;
  }

  .star-rating-list {
    margin-bottom: 40px;
    padding: 0 ${grid.minOuter}px;
    text-align: center;

    .star-item {
      display: inline-block;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }

      input,
      span {
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 38'><path d='M20,6.09l3.51,10.09,10.69.22-8.52,6.45,3.1,10.22L20,27l-8.78,6.1,3.1-10.22L5.8,16.4l10.69-.22L20,6.09M20,0,15.06,14.21,0,14.52,12,23.6,7.64,38,20,29.41,32.36,38,28,23.6l12-9.08-15.06-.31L20,0Z' fill='%23F59307'/></svg>") no-repeat;
        height: 28px;
        width: 28px;

        @media (min-width: ${breakpoints.iphone8.width}px) {
          height: 34px;
          width: 34px;
        }


        &.selected {
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 38'><path d='M7.64,38,12,23.6,0,14.52l15.06-.31L20,0l4.94,14.21L40,14.52,28,23.6,32.36,38,20,29.41Z' fill='%23F59307'/></svg>") no-repeat;        
        }
      }

      span {
        display: inline-block;
      }

      label {
        display: none;
      }
    }
  }

  .feedback-form {
    padding: 0 ${grid.minOuter}px;

    .feedback-textarea {
      ${fontFamily.sohneKraftig}
      border-radius: 4px;
      border: solid 1px ${colors.veryDarkGray};
      display: block;
      margin-bottom: 64px;
      padding: 20px 28px;
      width: 100%;
    }

    .feedback-textarea,
    .feedback-submit {
      ${colors.white};
      font-size: 16px;
      letter-spacing: -0.24px;
      line-height: 21px;
    }

    .submit-wrapper {
      text-align: center;

      .feedback-submit {
        ${fontFamily.sohneDreiviertelfett};
        display: inline-block;
        text-transform: uppercase;
      }
    }
  }

  .feedback-title-submitted {
    ${fontFamily.sohneLeicht}
    color: ${colors.darkGray};
    font-family: 36px;
    letter-spacing: -0.54px;
    line-height: 45px;
    margin-bottom: 24px;
    text-align: center;
  }

  .submitted-wrapper {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50vh;
    transform: translateY(-100%);
    width: 100%;

    .star-rating-list {
      margin-bottom: 0;
    }
  }

  .feedback-continue {
    display: inline-block;
    font-size: 16px;
    letter-spacing: -0.24px;
    line-height: 21px;
    text-transform: uppercase;
    transform: translateY(120px);

    @media (min-height: ${breakpoints.iphoneSe.height}px) {
      transform: translateY(140px);
    }

    @media (min-height: ${breakpoints.iphone8.height}px) {
      transform: translateY(180px);
    }

    @media (min-height: ${breakpoints.iphonePlus.height}px) {
      transform: translateY(210px);
    }

    @media (min-height: ${breakpoints.iphoneX.height}px) {
      transform: translateY(220px);
    }

    @media (min-height: ${breakpoints.iphoneXR.height}px) {
      transform: translateY(260px);
    }

    @media (min-height: ${breakpoints.iphoneXR.height}px) {
      transform: translateY(320px);
    }

    svg {
      display: inline-block;
      height: auto;
      margin-left: 14px;
      transform: translateY(2px);
      width: 16px;
    }
  }
`;
