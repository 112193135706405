import { actionsTypes } from '../constants/constants'
// import { LOCALES } from '../i18n'

const initialState = {
    dataAudioguide: [{}],
    originRoute: "",
    lang: '',
    averageRate: [],
    displayMenu: false,
    displayContent: true
}

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.SET_DATA_AUDIOGUIDE:
            return { ...state, dataAudioguide: action.dataAudioguide }
        case actionsTypes.SET_AVARAGE_RATE:
            return { ...state, averageRate: action.averageRate }
        case actionsTypes.SET_ORIGIN_ROUTE:
            return { ...state, originRoute: action.originRoute }
        case actionsTypes.SET_LANG:
            return { ...state, lang: action.lang }
        case actionsTypes.SET_DISPLAY_MENU:
            return { ...state, displayMenu: action.displayMenu }
        case actionsTypes.SET_DISPLAY_CONTENT:
            return { ...state, displayContent: action.displayContent }
        default:
            return state
    }
}

export { reducers }