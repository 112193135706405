import React from "react";

import { colors } from '../../ui/variables';

const YoutubeIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <title>YouTube</title>
    <g fill={color || colors.black}>
      <path d="M16.63,22.56,22.82,19l-6.19-3.56Z" />
      <path d="M19,0A19,19,0,1,0,38,19h0A19,19,0,0,0,19,0ZM30.87,19a31.23,31.23,0,0,1-.49,5.71,2.94,2.94,0,0,1-2.09,2.09c-1.86.49-9.29.49-9.29.49s-7.42,0-9.29-.51a3,3,0,0,1-2.1-2.09A31.22,31.22,0,0,1,7.13,19a31.23,31.23,0,0,1,.49-5.71,3,3,0,0,1,2.09-2.11c1.86-.49,9.29-.49,9.29-.49s7.43,0,9.29.51a2.94,2.94,0,0,1,2.09,2.09A29.77,29.77,0,0,1,30.87,19Z" />
    </g>
  </svg>
);

export default YoutubeIcon;
