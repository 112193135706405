import styled from 'styled-components';

import { breakpoints, colors, fontFamily, functions, grid, zIndex } from '../../ui/variables.js';

export const HeaderStyle = styled.div`
  color: ${colors.white};

  .header-wrapper {
    ${zIndex.space};
    left: 50%;
    max-width: ${breakpoints.ipad.width}px;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    width: 100%;

    .header-cont {
      ${zIndex.space};
      align-items: center;
      background-color: ${colors.black};
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: ${functions.responsive.vh(12, 14, breakpoints.iphone.height, breakpoints.iphone8.height)} ${grid.minOuter}px;
      position: relative;
      width: 100%;

      @media (min-height: ${breakpoints.iphone8.height}px) {
        padding: 14px ${grid.minOuter}px;
      }

      @media (min-height: ${breakpoints.iphoneX.height}px) {
        padding: 24px ${grid.minOuter}px;
      }

      .burguer-icon-wrapper {
        display: block;
        height: 36px;
        width: 36px;
      }

      .selected-language {
        ${fontFamily.sohneKraftig};
        color: ${colors.darkGray};
        font-size: 16px;
        line-height: 21px;
        padding-right: 22px;
        position: relative;

        .icon-wrapper {
          display: block;
          height: 16px;
          position: absolute;
          right: 0;
          top: 4px;
          transform: rotate(90deg);
          width: 16px;

          -webkit-transition-duration: 0.2s;
          -moz-transition-duration: 0.2s;
          -o-transition-duration: 0.2s;
          transition-duration: 0.2s;
          -webkit-transition-property: -webkit-transform;
          -moz-transition-property: -moz-transform;
          -o-transition-property: -o-transform;
          transition-property: transform;

          &.active {
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }      
        }
      }

      .language-wrapper {
        height: 0;
        left: 50%;
        max-width: 768px;
        position: fixed;
        position: relative;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
      }
    }
  }

  .breadcrumb-wrapper {
    height: 0;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.show {
      height: auto;
    }
  }
`;
