import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import { actions } from '../../actions/actions';

import QrCodeTarget from '../../components/svgs/QrCodeTarget';

import translate from '../../i18n/translate';
import { sendAmplitudeData } from '../../utilities/amplitude';

import { QrCodeStyle } from './qrcodeStyle';


const QrCode = ({ intl }) => {
  const dispatch = useDispatch();

  const setOriginRoute = useCallback(routeChoice => dispatch(
    actions.setOriginRoute(routeChoice)
  ), [dispatch]);

  const [code, setCode] = useState('');
  const [result, setResult] = useState('');
  const [webcamEnabled, setWebcamEnabled] = useState(true);
  const [codeNotFound, setCodeNotFound] = useState(false);

  const dataAudioguide = useSelector(state => state.audioGuideReducers.dataAudioguide);

  const history = useHistory();

  useEffect(() => { setOriginRoute('code') }, [setOriginRoute])

  const handleTyper = event => {
    if (event.target.value.length <= 2) {
      setCode(event.target.value.replace(/[^0-9]/g, ''));
      setCodeNotFound(false)
    }
  };

  const handleSubmitTyper = event => {
    event.preventDefault();

    let experiencesList = dataAudioguide.map(item => item.experiences);
    let mergedExperiences = [].concat.apply([], experiencesList);
    let experience = mergedExperiences.filter(
      item => item.code_number === code.replace(/^0+/, '')
    );

    if (experience.length > 0) {
      sendAmplitudeData('Codigo-digitado', experience[0]);
      history.push(`/experiencia/${experience[0].code_number}`);
    } else {
      alert('Esse código não existe');
    }
  };

  const handleScan = data => {
    if (data) {
      setResult(data);
      openQrCodeLink(data);
    }
  };

  const handleError = () => setWebcamEnabled(false);

  const openQrCodeLink = url => {
    let locationPathnameSplit = url.split('/');
    let idExperience = locationPathnameSplit[4]
    let experiencesList = dataAudioguide.map(item => item.experiences);
    let mergedExperiences = [].concat.apply([], experiencesList);
    let experience = [];
    if (idExperience && idExperience.length < 3) {
      experience = mergedExperiences.filter(item => item.code_number === idExperience);
    } else {
      experience = mergedExperiences.filter(item => item.id === idExperience);
    }
    sendAmplitudeData('Codigo-escaneado', experience[0]);
    window.open(url, '_self');
  }

  const onClickAllowWebcan = () => alert('Pedir permissão de câmera novamente.');

  return (
    <QrCodeStyle>
      <h1 className="code-title">{translate('code')}</h1>
      
      <p className={`code-instruction${codeNotFound ? ' code-not-found' : ''}`}>
        {
          codeNotFound ? translate('msgQRCodeError') : translate('msgQRCode')
        }
      </p>

      <form
        className={`code-form${code && code.length > 0 ? ' valid' : ''}`}
        onSubmit={handleSubmitTyper}
      >
        <input
          type="number"
          className="code-input"
          value={code}
          onChange={handleTyper}
          placeholder="00"
          maxLength={2}
        />
        <input
          type="submit"
          className="code-submit"
          value={intl.formatMessage({ id: 'btnEnterCode' })}
          disabled={(!(code && code.length > 0))}
        />
      </form>

      <div className="qr-code-wrapper">
        {webcamEnabled
          ? (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              onLoad={() => openQrCodeLink(result)}
            />
          ) : null
        }
        <div className="code-target">
          <QrCodeTarget allowed={webcamEnabled} />
          {!webcamEnabled
            ? (
              <button
                type="button"
                className="allow-webcan"
                onClick={onClickAllowWebcan}
              >
                {translate('allowCam')}
              </button>
            ) : null
          }
        </div>
      </div>
    </QrCodeStyle>
  );
};

export default injectIntl(QrCode);
