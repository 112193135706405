import React from "react";

const BurguerIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
            <title>Menu</title>
            <rect y="5.5" width="34" height="4" fill="#F59307" />
            <rect y="15.5" width="34" height="4" fill="#F59307" />
            <rect y="25.5" width="34" height="4" fill="#F59307" />
        </svg>
    );
}

export default BurguerIcon;
