import React, { useEffect, useState, useCallback, createRef } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { I18nProvider } from './i18n';

import * as localStorage from './utilities/localStorage';
import { initAmplitude } from './utilities/amplitude';
import { actions } from './actions/actions';

import Code from './views/qrCode/qrcode';
import Experience from './views/experience/experience';
import Feedback from './views/feedback/feedback';
import List from './views/list/list';
import Map from './views/map/map';
import MapPresentation from './views/mapPresentation/mapPresentation';
import NotFound from './views/notFound/notFound';
import Pavements from './views/pavements/pavements';

import Header from './components/Header/Header';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import Loading from './components/Loading/Loading'
import MainContent from './components/MainContent/MainContent';
import NavigationBar from './components/NavigationBar/NavigationBar';
import LogoMlp from './components/svgs/LogoMlp';

import PageTransitionStyle from './ui/pageTransitionStyle'
import GlobalStyle from './GlobalStyle';
import { pageTransitionTime } from './components/MainContent/MainContentStyle';
import './App.css';

const App = () => {
  const [locale, setLocale] = useState();
  const [firstTime, setFirstTime] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [isConstruction] = useState(false);
  const [lastPathname, setLastPathname] = useState('');

  const localeDefault = useSelector(state => state.audioGuideReducers.lang);

  const displayContent = useSelector(state => state.audioGuideReducers.displayContent);
  // const localeDefault = useSelector(state => state.audioGuideReducers.lang);

  const dispatch = useDispatch();
  const history = useHistory();

  const setDataAudioguide = useCallback(dataAudioguide => dispatch(
    actions.setDataAudioguide(dataAudioguide)
  ), [dispatch]);

  const setAverageRate = useCallback(averageRate => dispatch(
    actions.setAverageRate(averageRate)
  ), [dispatch]);

  const setLanguage = useCallback(lang => dispatch(
    actions.language(lang)
  ), [dispatch]);

  const handleSelectLanguage = chosenLanguage => {
    localStorage.set('lang', chosenLanguage);
    setLanguage(chosenLanguage);
  };

  const getDirection = pathname => {
    if (
      pathname === lastPathname ||
      pathname.includes('/satisfacao')
    ) {
      return 'none';
    }

    if (
      //from codigo to indice
      (lastPathname === '/codigo' && pathname === '/') ||

      //from indice to listagem
      (lastPathname === '/' && pathname.includes('/listagem')) ||
      
      //from codito to mapa
      (lastPathname === '/codigo' && pathname === '/mapa') ||

      //from indice to mapa
      (lastPathname === '/' && pathname === '/mapa') ||

      //from mapa to open mapa
      (lastPathname === '/mapa' && pathname.includes('/mapa')) ||

      //from listagem to open mapa
      (lastPathname.includes('/listagem') && pathname.includes('/mapa')) ||

      //to experience
      pathname.includes('/experiencia')
    ) {
      return 'right';
    }

    return 'left';
  }

  useEffect(() => {
    initAmplitude();

    const fetchData = async () => {
      await axios.get(process.env.REACT_APP_API)
        .then((response) => {
          setDataAudioguide(response.data.data)
          setAverageRate(response.data.averageRate)
          localStorage.get('lang').then(data => {
            if (data !== undefined) {
              setLocale(data);
              setLanguage(data);
              setFirstTime(false);
            }

            setLoaded(true);
          });
        })
        .catch(error => console.log(error.message))
    }

    fetchData();
  }, [setDataAudioguide, setAverageRate, setLanguage, localeDefault, history]);

  return (
    <I18nProvider locale={locale}>
      <GlobalStyle />
      <MainContent>
        <Router>
          {
            isConstruction ?
              <div style={{ textAlign: 'center', marginTop: 180 }}>
                <h1>PÁGINA EM CONSTRUÇÃO</h1>
              </div>
              :
              loaded
                ? firstTime
                  ? (
                    <div className="language-selector-wrapper">
                      <h1 className="language-title-logo">
                        <span className="logo-wrapper">
                          <LogoMlp />
                        </span>
                      </h1>
                      <LanguageSelector
                        currentLanguage={locale}
                        selectLanguage={handleSelectLanguage}
                        show={firstTime}
                      />
                    </div>
                  ) : (
                    <>
                      <Header selectLanguage={handleSelectLanguage} />
                      {
                        displayContent ?
                          <Route
                            render={({ location }) => {
                              var nodeRef = createRef()
                              return (
                                <TransitionGroup
                                  appear={true}
                                  className={`main-content-wrapper ${getDirection(location.pathname)}`}
                                >
                                  <CSSTransition
                                    nodeRef={nodeRef}
                                    key={location.key}
                                    classNames="page-transition"
                                    timeout={{
                                      appear: 0,
                                      enter: pageTransitionTime - 5,
                                      exit: pageTransitionTime - 15
                                    }}
                                    unmountOnExit={true}
                                    onExiting={() => setLastPathname(location.pathname)}
                                    onEntered={() => window.scrollTo(0, 0)}
                                  >
                                    <PageTransitionStyle ref={nodeRef}>
                                      <Switch location={location}>
                                        <Route
                                          path="/experiencia/:id_experiencia"
                                          component={Experience}
                                        />
                                        <Route path="/listagem/:id_listagem" component={List} />
                                        <Route path="/mapa/:id_map" component={MapPresentation} />
                                        <Route path="/mapa" component={Map} />
                                        <Route path="/codigo" component={Code} />
                                        <Route path="/satisfacao" component={Feedback} />
                                        <Route path="/" component={Pavements} />
                                        <Route component={NotFound} />
                                      </Switch>
                                    </PageTransitionStyle>
                                  </CSSTransition>
                                </TransitionGroup>
                              );
                            }}
                          />
                          :
                          null
                      }
                      <NavigationBar currentLanguage={locale} />
                    </>
                  )
                : null
          }
          <Loading loaded={loaded} />
        </Router>
      </MainContent>

    </I18nProvider>
  );
}

export default App
