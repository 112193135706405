import React, { useState, useEffect, useRef } from 'react';
import translate from '../../i18n/translate'
import { TextContentStyle } from './TextContentStyle';
import truncate from 'html-truncate';

const TextContent = ({ content, customRef, style }) => {
  const [truncable, setTruncable] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (content && content.length > 800) {
      setTruncable(true);
      setVisible(false);
    }
  }, [content]);

  const toggleVisibility = () => {
    if(!visible && nodeRef.current){
      nodeRef.current.focus();
      console.log("first", nodeRef.current)
    }
    setVisible(!visible);
  }

  const truncatedContent = visible ? content : truncate(content, 400);

  const nodeRef = useRef(null);

  return (
    <TextContentStyle className="content" ref={customRef} style={{ ...style }}>
      <div className={`text-content-wrapper${visible ? ' visible' : ''}${truncable ? ' truncable' : ''}`}>
        <div ref={nodeRef} tabIndex="-1" className="text-cont" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {truncable
          ? (
            <div className="toggle-visibility-wrapper">
              <button className="toggle-visibility" onClick={toggleVisibility}>
                {visible ? translate("readLess") : translate("readMore")}
              </button>
            </div>
          ) : null}
      </div>
    </TextContentStyle>
  );
};

export default TextContent;
