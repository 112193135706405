import styled from 'styled-components';

import { colors, fontFamily } from '../../ui/variables.js';

export const AudioContentStyle = styled.div`
  .audio-title {
    ${fontFamily.sohneKraftig};
    color: ${colors.white};
    font-size: 16px;
    letter-spacing: -0.24px;
    line-height: 21px;

    & + .audio-author {
      margin-bottom: 0;
      margin-top: 2px;
    }
  }

  .audio-author {
    ${fontFamily.sohneLeicht};
    color: ${colors.vividOrange};
    font-size: 14px;
    letter-spacing: -0.21px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  .audio-title,
  .audio-author {
    padding-right: 38px;

    & + .player-container {
      margin-top: 12px;
    }
  }

  .player-container {
    padding-right: 38px;
    position: relative;
    width: 100%;

    .progress-wrapper {
      height: 32px;
      width: 100%;

      .progress-border {
        border: solid 2px ${colors.white};
        border-radius: 16px;
        height: 100%;
        position: relative;
        width: 100%;

        .progress-percentage {
          height: 22px;
          left: 3px;
          overflow: hidden;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: calc(100% - 6px);

          .progress-percentage-fill {
            background-color: ${colors.vividOrange};
            border-radius: 12px;
            height: 22px;
            min-width: 22px;
            width: calc(100% - 6px);
          }
        }
      }
    }

    .play-button {
      height: 25px;
      position: absolute;
      right: 0;
      top: 3.5px;
      width: 25px;
    }

    .current-time {
      ${fontFamily.sohneLeicht};
      color: ${colors.vividOrange};
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      right: 0;
      top: -30px;
    }
  }
`;
