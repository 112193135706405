import React from "react";

import { colors } from '../../ui/variables';

const InstagramIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <title>Instagram</title>
    <g fill={color || colors.black}>
      <path d="M22.33,18.74a3.59,3.59,0,1,1-3.59-3.59h0A3.59,3.59,0,0,1,22.33,18.74Z" />
      <path d="M27.13,12.39a3.56,3.56,0,0,0-2-2,6,6,0,0,0-2-.37c-1.13-.05-1.47-.06-4.35-.06s-3.21,0-4.34.06a6.16,6.16,0,0,0-2,.37,3.6,3.6,0,0,0-2.05,2.05,6.16,6.16,0,0,0-.37,2c-.05,1.13-.06,1.47-.06,4.34s0,3.22.06,4.35a6.21,6.21,0,0,0,.37,2,3.56,3.56,0,0,0,2,2,5.76,5.76,0,0,0,2,.37c1.14.06,1.48.07,4.35.07s3.22,0,4.35-.07a5.71,5.71,0,0,0,2-.37,3.54,3.54,0,0,0,2-2,5.71,5.71,0,0,0,.37-2c.06-1.13.07-1.47.07-4.35s0-3.21-.07-4.34A5.73,5.73,0,0,0,27.13,12.39ZM18.75,24.27a5.53,5.53,0,1,1,5.52-5.53,5.53,5.53,0,0,1-5.52,5.53h0Zm5.74-10A1.3,1.3,0,1,1,25.78,13a1.29,1.29,0,0,1-1.29,1.29h0Z" />
      <path d="M19,0A19,19,0,1,0,38,19h0A19,19,0,0,0,19,0ZM29.85,23.5a8,8,0,0,1-.51,2.65,5.6,5.6,0,0,1-3.19,3.19,8,8,0,0,1-2.65.5c-1.16.06-1.53.07-4.5.07s-3.33,0-4.49-.07a8,8,0,0,1-2.65-.5,5.57,5.57,0,0,1-3.19-3.19,8,8,0,0,1-.51-2.65C8.11,22.33,8.09,22,8.09,19s0-3.33.07-4.5a8,8,0,0,1,.51-2.65,5.57,5.57,0,0,1,3.19-3.19,8,8,0,0,1,2.65-.5C15.67,8.1,16,8.09,19,8.09s3.34,0,4.5.07a8,8,0,0,1,2.65.5,5.6,5.6,0,0,1,3.19,3.19,8,8,0,0,1,.51,2.65c0,1.17.06,1.54.06,4.5s0,3.33-.07,4.5Z" />
    </g>
  </svg>
);

export default InstagramIcon;
