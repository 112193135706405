import styled from 'styled-components';

import { breakpoints, colors, fontFamily, functions } from '../../ui/variables';

export const MapPresentationStyle = styled.div`
  padding-top: 40px;

  .map-container {
    // padding: 64px 28px 0;
    position: relative;
    text-align: center;
    transform-origin: top left;

    .map-image-wrapper {
      display: block;
      margin: 0 auto;
      position: relative;

      .image-map {
        display: block;
        height: auto;
        width: 100%;
      }

      .map-point-list {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        .map-point {
          position: absolute;

          &.text-centralized {
            .point-link {
              .point-name {
                left: ${functions.responsive.vw(11 , 26, breakpoints.iphoneX.width, breakpoints.ipad.width)};
                transform: translate(-50%, 100%);

                @media (min-width: ${breakpoints.ipad.width}px) {
                  left: 26px;
                }
              }
            }
          }

          &.text-left {
            .point-link {
              .point-name {
                left: ${functions.responsive.vw(-8, -20, breakpoints.iphoneX.width, breakpoints.ipad.width)};
                transform: translateX(-100%);

                @media (min-width: ${breakpoints.ipad.width}px) {
                  left: -20px;
                }
              }
            }
          }

          &.text-right {
            .point-link {
              .point-name {
                left: ${functions.responsive.vw(30, 72, breakpoints.iphoneX.width, breakpoints.ipad.width)};

                @media (min-width: ${breakpoints.ipad.width}px) {
                  left: 70px;
                }
              }
            }
          }

          &.text-invisible {
            .point-link {
              .point-name {
                display: none;
              }
            }
          }

          .point-link {
            position: relative;

            .point-code,
            .point-name {
              display: block;
              font-size: ${functions.responsive.vw(16, 32, breakpoints.iphoneX.width, breakpoints.ipad.width)};
              letter-spacing: 0.315px;
              line-height: ${functions.responsive.vw(21, 50, breakpoints.iphoneX.width, breakpoints.ipad.width)};
              position: absolute;

              @media (min-width: ${breakpoints.ipad.width}px) {
                font-size: 32px;
                line-height: 50px;
              }
            }

            .point-code {
              ${fontFamily.sohneKraftig}
              background-color: ${colors.vividRed};
              border-radius: 50%;
              height: ${functions.responsive.vw(22, 52, breakpoints.iphoneX.width, breakpoints.ipad.width)};
              max-width: ${functions.responsive.vw(22, 52, breakpoints.iphoneX.width, breakpoints.ipad.width)};
              min-width: ${functions.responsive.vw(22, 52, breakpoints.iphoneX.width, breakpoints.ipad.width)};
              text-align: center;

              @media (min-width: ${breakpoints.ipad.width}px) {
                height: 52px;
                max-width: 52px;
                min-width: 52px;
              }
            }

            .point-name {
              ${fontFamily.sohneDreiviertelfett}
              text-align: left;
              text-shadow: 2px 2px 4px ${colors.veryDarkGray};
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
`;
