import styled from 'styled-components';

import { colors } from '../../ui/variables';

export const NotFoundStyle = styled.div`
    margin: auto;
    text-align: center;
    width: 80%;
    margin-top: 120px;
    color: ${colors.vividOrange};
`;
