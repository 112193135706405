import React, { Fragment } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';

import { LOCALES } from './locales';
import messages from './messages'

const Provider = ({ children, locale = LOCALES.PORTUGUESE }) => (
    <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={messages[locale]}
        onError = {(e) => {if (e.code === ReactIntlErrorCode.MISSING_DATA){return}}}
    >
        {children}
    </IntlProvider>
)

export default Provider