import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { sendAmplitudeData } from '../../utilities/amplitude'

import PlayIcon from '../svgs/PlayIcon';
import PauseIcon from '../svgs/PauseIcon';

import { colors } from '../../ui/variables';

import { AudioContentStyle } from './AudioContentStyle';

import {injectIntl} from 'react-intl';

const AudioContent = ({ intl, content, customRef, style, controlPlay, playingId }) => {

  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const audioRef = useRef();

  const timeFormatted = currentTime => {
    const minute = parseInt((currentTime / 60), 10) % 60;
    const secondsLong = currentTime % 60;
    const seconds = secondsLong.toFixed();
    const formatted = `${minute}:${seconds < 10 ? `0${seconds}` : seconds}`;

    return formatted;
  };

  const timeFormattedScreenReader = currentTime => {
    const minute = parseInt((currentTime / 60), 10) % 60;
    const secondsLong = currentTime % 60;
    const seconds = secondsLong.toFixed();
    var formatted = "";
    if(minute === 1) 
      formatted = minute + intl.formatMessage({id: "minute"})
    else if(minute > 1) 
      formatted = minute + intl.formatMessage({id: "minutes"})
    if(seconds > 0){
      if(formatted)
        formatted += " " + intl.formatMessage({id: "and"}) + " "
      if(seconds === 1) 
        formatted = seconds + intl.formatMessage({id: "second"})
      else if(seconds > 1) 
        formatted = seconds + intl.formatMessage({id: "seconds"})
    }
    return formatted;
  };

  const setupProgress = useCallback(() => {
    setCurrentTime(audioRef.current.currentTime);
  }, []);

  const onClickPlayButton = () => {
    setPlaying(!playing);
    controlPlay()
  }

  const onClickProgressBorder = event => {
    const offsetX = event.nativeEvent.offsetX;
    const offsetWidth = event.currentTarget.offsetWidth;

    audioRef.current.currentTime = (offsetX / offsetWidth) * duration;
  };

  const onAudioEnded = () => {
    setPlaying(false);
    setCurrentTime(0);
    setPercentage(0);
  };

  const onAudioCanPlayThrough = () => {
    setDuration(audioRef.current.duration);
    setupProgress();
  }

  useEffect(()=>{
    if(playingId !== content.id && playing) setPlaying(false)
  },[playingId, content, playing])

  useEffect(() => {
    if (!playing) {
      audioRef.current.pause();
    } else {
      sendAmplitudeData("Audio", content); 
      audioRef.current.play();
    }
  }, [playing, content]);

  useEffect(() => {
    const percentagePlayed = (currentTime / duration).toFixed(2) * 100;

    setPercentage(percentagePlayed);
  }, [currentTime, setCurrentTime, duration, setDuration]);
  
  var label = intl.formatMessage({id: (playing ? "pauseAudio" : "playAudio")});
  if(content && content.audio_title) label += ", " + content.audio_title;
  if(content && content.audio_author) label += ", " + content.audio_author;
  if(duration) label += ", " + timeFormattedScreenReader(duration);

  return (
    <AudioContentStyle className="content" ref={customRef} style={{...style}}>
      {
        content && content.audio_title
          ? <h4 className="audio-title" aria-hidden="true">{content.audio_title}</h4>
          : null
      }
      {
        content && content.audio_author
          ? <h5 className="audio-author" aria-hidden="true">{content.audio_author}</h5>
          : null
      }
      <div className="player-container">
        <button className="play-button" onClick={onClickPlayButton} aria-pressed={playing} aria-label={label}>
          {
            playing
            ? (
              <PauseIcon
                background={colors.vividOrange}
                color={colors.black}
                rounded
              />
            ) : (
              <PlayIcon
                background={colors.vividOrange}
                color={colors.black}
                rounded
              />
            )
          }
        </button>
        <div className="progress-wrapper" aria-hidden="true">
          <button className="progress-border" onClick={onClickProgressBorder}>
            <div className="progress-percentage" style={{ width: `${percentage}%` }}>
              <div className="progress-percentage-fill" />
            </div>
          </button>
        </div>
        <p className="current-time" aria-hidden="true">
          {currentTime || duration ? timeFormatted(currentTime || duration) : ''}
        </p>
      </div>
      <audio
        ref={audioRef}
        src={content.url}
        onCanPlayThrough={onAudioCanPlayThrough}
        onTimeUpdate={setupProgress}
        onEnded={onAudioEnded}
        controls
        hidden
      >
        <p>Seu nevegador não suporta o elemento audio.</p>
      </audio>
    </AudioContentStyle>
  );
};

export default injectIntl(AudioContent);
