import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import SmallArrow from '../../components/svgs/SmallArrow';

import { colors } from '../../ui/variables';
import { sendAmplitudeData } from '../../utilities/amplitude';
import * as localStorage from '../../utilities/localStorage';
import translate from '../../i18n/translate';

import { FeedbackStyle } from './feedbackStyle';

const Feedback = ({ intl }) => {
  const [rating, setRating] = useState(0);
  const [id, setId] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    localStorage.get('feedback').then(data => {
      if (data && data.rating) setRating(data.rating)
      if (data && data.id) setId(data.id)
      if (data && data.feedbackText) setFeedbackText(data.feedbackText)
    });
  }, [])

  const onClickStar = rate => setRating(rate);

  const onChangeFeedbackTextarea = text => setFeedbackText(text);

  const onSubmitFeedback = event => {
    event.preventDefault();
    if (id !== '') {
      axios.put(process.env.REACT_APP_RATINGS + "/" + id, {
        rate: rating,
        comment: feedbackText
      }).then((response) => {
        if (response) {
          let responseFeedback = {
            rating: response.data.rate,
            feedbackText: response.data.comment,
            id: response.data.id
          }
          localStorage.set('feedback', responseFeedback);
        }
      });
    } else {
      axios.post(process.env.REACT_APP_RATINGS, {
        rate: rating,
        comment: feedbackText
      }).then((response) => {
        if (response && response.data && response.data.id) {
          let responseFeedback = {
            rating: rating,
            feedbackText: feedbackText,
            id: response.data.id
          }
          localStorage.set('feedback', responseFeedback);
        }
      });
    }
    sendAmplitudeData('Feedback', { rating, feedbackText });
    setSubmitted(true);

  }

  return (
    <FeedbackStyle>
      {submitted
        ? (
          <div className="submitted-wrapper">
            <h1 className="feedback-title-submitted">
              {translate('thanks')}
            </h1>
            <div className="star-rating-list" aria-hidden="true">
              <div className="star-item">
                <span
                  className={rating >= 1 ? 'selected' : ''}
                  aria-label="1"
                />
              </div>
              <div className="star-item">
                <span
                  className={rating >= 2 ? 'selected' : ''}
                  aria-label="2"
                />
              </div>
              <div className="star-item">
                <span
                  className={rating >= 3 ? 'selected' : ''}
                  aria-label="3"
                />
              </div>
              <div className="star-item">
                <span
                  className={rating >= 4 ? 'selected' : ''}
                  aria-label="4"
                />
              </div>
              <div className="star-item">
                <span
                  className={rating >= 5 ? 'selected' : ''}
                  aria-label="5"
                />
              </div>
            </div>
            <button
              className="feedback-continue"
              onClick={() => history.push('/')}
            >
              {translate('continue')}
              <SmallArrow color={colors.vividOrange} />
            </button>
          </div>
        ) : (
          <>
            <h1 className="feedback-title">{translate('hello')}</h1>
            <h2 className="feedback-description">
              {translate('descriptionFeedbak')}
            </h2>
            <form className="feedback-form" onSubmit={onSubmitFeedback}>
              <div className="star-rating-list">
                <div className="star-item">
                  <input
                    type="radio"
                    className={rating >= 1 ? 'selected' : ''}
                    name="star"
                    value="1"
                    aria-label="1"
                    onChange={() => onClickStar(1)}
                  />
                </div>
                <div className="star-item">
                  <input
                    type="radio"
                    className={rating >= 2 ? 'selected' : ''}
                    name="star"
                    value="2"
                    aria-label="2"
                    onChange={() => onClickStar(2)}
                  />
                </div>
                <div className="star-item">
                  <input
                    type="radio"
                    className={rating >= 3 ? 'selected' : ''}
                    name="star"
                    value="3"
                    aria-label="3"
                    onChange={() => onClickStar(3)}
                  />
                </div>
                <div className="star-item">
                  <input
                    type="radio"
                    className={rating >= 4 ? 'selected' : ''}
                    name="star"
                    value="4"
                    aria-label="4"
                    onChange={() => onClickStar(4)}
                  />
                </div>
                <div className="star-item">
                  <input
                    type="radio"
                    className={rating >= 5 ? 'selected' : ''}
                    name="star"
                    value="5"
                    aria-label="5"
                    onChange={() => onClickStar(5)}
                  />
                </div>
              </div>
              <textarea
                className="feedback-textarea"
                rows="5"
                onChange={event => onChangeFeedbackTextarea(event.target.value)}
                value={feedbackText}
                placeholder={intl.formatMessage({ id: 'placeholderTxtBoxFeedback' })}
              />
              <div className="submit-wrapper">
                <button type="submit" className="feedback-submit">
                  {translate('submit')}
                </button>
              </div>
            </form>
          </>
        )
      }
    </FeedbackStyle>
  );
};

export default injectIntl(Feedback);
