import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../actions/actions';

import PavementsList from '../../components/PavementsList/PavementsList';

import { colors } from '../../ui/variables.js';

const Map = () => {
  const dispatch = useDispatch();

  const setOriginRoute = useCallback(routeChoice => dispatch(
    actions.setOriginRoute(routeChoice)
  ), [dispatch]);

  const dataAudioguide = useSelector(
    state => state.audioGuideReducers.dataAudioguide
  );
  const locale = useSelector(state => state.audioGuideReducers.lang);
  const history = useHistory();

  const [sortedDataAudioguide, setSortedDataAudioguide] = useState([]);

  const setFloorMapUser = floor => history.push(`/mapa/${floor}`);

  useEffect(() => {
    setOriginRoute('map')
    setSortedDataAudioguide(dataAudioguide.sort((a, b) => a.code - b.code));
  }, [dataAudioguide, setOriginRoute]);

  return (
    <>
      <PavementsList
        pavements={sortedDataAudioguide}
        setPavements={setFloorMapUser}
        locale={locale}
        color={colors.vividRed}
      />
    </>
  )
}

export default Map