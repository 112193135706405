import React, { useState, useEffect, useRef } from 'react';

import AudioDescriptionIcon from '../svgs/AudioDescriptionIcon';
import { sendAmplitudeData } from '../../utilities/amplitude'
import { colors } from '../../ui/variables';
import { LOCALES } from '../../i18n';
import { Transition } from 'react-transition-group';

import { LanguageSelectorStyle } from './LanguageSelectorStyle';


const LanguageSelector = ({
  currentLanguage,
  selectLanguage,
  show,
  floating
}) => {
  const [delayAnimation, setDelayAnimation] = useState(false);
  const [languageSelectorClassName, setLanguageSelectorClassName] = useState(false);

  const firstLanguage = useRef()
  const langRef = useRef()

  useEffect(() => {
    if (show && floating) {
      setDelayAnimation(false)
    }
  }, [show, floating]);

  useEffect(() => {
    let classes = '';

    classes = show ? `${classes} show` : classes;
    classes = floating ? `${classes} floating` : classes;
    classes = delayAnimation ? `${classes} delay-animation` : classes;
    classes = classes.trim();

    setLanguageSelectorClassName(classes);
  }, [delayAnimation, floating, show]);

  useEffect(()=>{
    if (show) firstLanguage.current.focus()
  },[show])

  const onClickLanguageButton = (e, language) => {
    e.stopPropagation();
    sendAmplitudeData("Idioma", { language });
    selectLanguage(language);
    setDelayAnimation(true);
  } 

  return (
    <Transition
      nodeRef={langRef}
      in={show}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={600}
    >
      <LanguageSelectorStyle ref={langRef} className={languageSelectorClassName}>

        <ul className="language-list" role="banner" >

          <p tabIndex="-1" aria-label="Selecione o idioma desejado." />
          
          <li className="language-item">
            <button
              ref={firstLanguage}
              className={`item-button with-icon language-${LOCALES.PORTUGUESE}${currentLanguage === LOCALES.PORTUGUESE ? ' active' : ''
                }`}
              onClick={(e) => onClickLanguageButton(e, LOCALES.PORTUGUESE)}
            >
              Português
              <span className="icon">
                <AudioDescriptionIcon
                  color={floating ? colors.black : colors.veryDarkGray}
                />
              </span>
            </button>
          </li>
          <li className="language-item">
            <button
              className={`item-button language-${LOCALES.LIBRAS}${currentLanguage === LOCALES.LIBRAS ? ' active' : ''
                }`}
              onClick={(e) => onClickLanguageButton(e, LOCALES.LIBRAS)}
            >
              Libras
            </button>
          </li>
          <li className="language-item">
            <button
              className={`item-button language-${LOCALES.SPANISH}${currentLanguage === LOCALES.SPANISH ? ' active' : ''
                }`}
              onClick={(e) => onClickLanguageButton(e, LOCALES.SPANISH)}
            >
              Español
            </button>
          </li>
          <li className={`language-item`}
          >
            <button
              className={`item-button language-${LOCALES.ENGLISH}${currentLanguage === LOCALES.ENGLISH ? ' active' : ''
                }`}
              onClick={(e) => onClickLanguageButton(e, LOCALES.ENGLISH)}
            >
              English
            </button>
          </li>
        </ul>
      </LanguageSelectorStyle>
    </Transition>
  );
};

export default LanguageSelector;
