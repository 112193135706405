import { breakpoints } from '../ui/variables';

const MIN_CHARACTERS = 26;
const MAX_CHARACTERS = 70;
const MIN_WIDTH = breakpoints.iphoneX.width;
const MAX_WIDTH = breakpoints.ipad.width;

export const textLengthByScreenWidth = (
  text,
  screenWidth = MIN_WIDTH,
  minCharacters = MIN_CHARACTERS,
  maxCharacters = MAX_CHARACTERS,
  minWidth = MIN_WIDTH,
  maxWidth = MAX_WIDTH
) => {
  const widthDiff = maxWidth - minWidth;
  const charactersDiff = maxCharacters - minCharacters;

  let currentMaxLength = Math.round(
    minCharacters + (((screenWidth - minWidth) * charactersDiff) / widthDiff)
  );

  if (currentMaxLength < minCharacters) {
    currentMaxLength = minCharacters;
  }

  if (currentMaxLength > maxCharacters) {
    currentMaxLength = maxCharacters;
  }

  if (text && text.length && text.length > currentMaxLength) {
    return `${(text.substr(0, currentMaxLength)).trim()}…`
  }

  return text;
};
