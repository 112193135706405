import styled from 'styled-components';

import { breakpoints, colors, fontFamily, grid, zIndex } from '../../ui/variables.js';

export const BreadcrumbStyle = styled.ol`
  ${zIndex.hills};
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.black};
  display: flex;
  padding: 10px ${grid.minOuter}px;
  transform: translate3d(0, -120%, 0);
  transition: transform 500ms;

  &.show {
    transform: translate3d(0, 0, 0);
  }

  .breadcrumb-item {
    display: inline-block;
    line-height: 0;

    .breadcrumb-button {
      ${fontFamily.sohneKraftig};
      font-size: 14px;
      letter-spacing: -0.24px;
      line-height: 18px;
      margin-bottom: 2px;

      @media (min-width: ${breakpoints.iphoneX.width}px) {
        font-size: 16px;
        line-height: 22px;
      }

      &.home-button {
        display: inline-block;
        position: relative;
        top: -1px;
        width: 20px;

        @media (min-width: ${breakpoints.iphoneX.width}px) {
          width: 24px;
        }
      }
    }

    .arrow-wrapper {
      display: inline-block;
      margin: 0 4px;
      width: 14px;

      @media (min-width: ${breakpoints.iphoneX.width}px) {
        margin: 0 6px;
        width: 16px;
      }

      svg {
        position: relative;
        top: 2px;
      }
    }
  }
`;
