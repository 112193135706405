import React from "react";

const AudioDescriptionIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 22"
    fill={color || '#fff'}
  >
    <title>com Audiodescrição</title>
    <path d="M6.7,8l1.63,4.51H5.09ZM5.05,4.64.29,17.53h3l.93-2.59H9.24l.94,2.59h3L8.48,4.64Z" />
    <path d="M18.94,15c2.31,0,3.62-1.55,3.62-3.92s-1.31-3.91-3.64-3.91H17.29V15ZM14.36,4.64h4.58c4.09,0,6.59,2.51,6.59,6.44s-2.5,6.45-6.59,6.45H14.36Z" />
    <path d="M27,2.2,25.51,3.66a10.4,10.4,0,0,1,0,14.69L27,19.81A12.48,12.48,0,0,0,27,2.2" />
    <path d="M29.16,0,27.7,1.46a13.51,13.51,0,0,1,0,19.08L29.16,22a15.57,15.57,0,0,0,0-22" />
  </svg>
);

export default AudioDescriptionIcon;
