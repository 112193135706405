import React from 'react';
import { injectIntl } from 'react-intl';

import SmallArrow from '../../components/svgs/SmallArrow';

import translate from '../../i18n/translate';
import { sendAmplitudeData } from '../../utilities/amplitude'
import { colors } from '../../ui/variables.js';

import { PavementsListStyle } from './PavementsListStyle';


const PavementsList = ({ intl, pavements, setPavements, locale, color }) => {
  const formatSubtitle = subtitle => {
    if (subtitle && subtitle.length) {
      return subtitle.replace(/(?:\r\n|\r|\n)/g, '</br>');
    }

    return '';
  };

  const chosenPaviment = pavement =>{
    sendAmplitudeData('Pavimento', pavement); 
    setPavements(pavement.slug);
  }

  return (
    <PavementsListStyle>
      {pavements.map(pavement => {
        const pavementCode = pavement.code;
        const pavementSubtitle = formatSubtitle(pavement[`subtitle_${locale}`]);

        return (
          <li key={pavement.id} className="pavement-item">
            <button
              className="go-to-pavement"
              onClick={() => chosenPaviment(pavement)}
            >
              <h2 className="pavement-title">
                <span
                  className={"pavement-code number" + pavementCode}
                  aria-label={intl.formatMessage({ id: `cardinal${pavementCode}` })}
                >
                  {pavementCode}
                </span>
                {' '}
                <span className="pavement-name">
                  {pavementCode === 0
                    ? pavement[`title_${locale}`]
                    : translate('floor')}
                </span>
              </h2>
              {pavementSubtitle && pavementSubtitle.length
                ? (
                  <h3
                    className="pavement-subtitle"
                    dangerouslySetInnerHTML={{ __html: pavementSubtitle }}
                  />
                ) : null}
              <span className="icon">
                <SmallArrow color={color || colors.white} />
              </span>
            </button>
          </li>
        );
      })}
    </PavementsListStyle>
  );
};

export default injectIntl(PavementsList);
