import styled from 'styled-components';

import { colors, fontFamily } from '../../ui/variables.js';

export const VideoContentStyle = styled.div`
  .video-wrapper {
    height: 0;
    line-height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    & > iframe,
    & > object,
    & > embed {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .player-youtube {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .capa-video {
    position: absolute;
    top: 120px;
    z-index: 999;
  }

  .video-title {
    ${fontFamily.sohneKraftig};
    color: ${colors.white};
    font-size: 16px;
    letter-spacing: -0.24px;
    line-height: 21px;

    & + .video-wrapper {
      margin-top: 12px;
    }
  }
`;
