import styled from 'styled-components';

import { breakpoints, colors, functions, zIndex } from '../../ui/variables.js';

export const SlideshowStyle = styled.div`
  .slideshow-item {
    background-position: center;
    background-size: cover;
    height: ${functions.responsive.vw(
      120,
      172,
      breakpoints.iphone.width,
      breakpoints.iphoneXR.width)};
    height: 120px;
    width: 100%;

    @media (min-width: ${breakpoints.iphoneXR.width}px) {
      height: ${functions.responsive.vw(
        172,
        320,
        breakpoints.iphoneXR.width,
        breakpoints.ipad.width)};
    }

    @media (min-width: ${breakpoints.ipad.width}px) {
      height: 320px;
    }

    .item-image-file {
      ${zIndex.hills};
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .control-dots {
    bottom: 10px;
    line-height: 0;
    margin: 0;
    padding: 0;

    .dot {
      background-color: transparent;
      border: solid 1px ${colors.white};
      box-shadow: none;
      height: 8px;
      opacity: 1;
      transition: background-color 300ms;
      width: 8px;

      &.selected {
        background-color: ${colors.white};
      }
    }
  }
`;
