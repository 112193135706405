import React from "react";

import { colors } from '../../ui/variables';

const TwitterIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
    <title>Twitter</title>
    <g fill={color || colors.black}>
        <path d="M19,0A19,19,0,1,0,38,19h0A19,19,0,0,0,19,0Zm8.68,14.81c0,.19,0,.38,0,.57A12.33,12.33,0,0,1,15.45,27.79h-.17a12.41,12.41,0,0,1-6.69-2,9.2,9.2,0,0,0,1,.06A8.77,8.77,0,0,0,15.05,24,4.36,4.36,0,0,1,11,21a4.38,4.38,0,0,0,2-.08,4.36,4.36,0,0,1-3.5-4.27v-.06a4.26,4.26,0,0,0,2,.55,4.38,4.38,0,0,1-1.35-5.82,12.42,12.42,0,0,0,9,4.56,4.36,4.36,0,0,1,7.43-4,8.56,8.56,0,0,0,2.77-1.06,4.34,4.34,0,0,1-1.92,2.41,8.73,8.73,0,0,0,2.51-.68A8.93,8.93,0,0,1,27.68,14.81Z" />
    </g>
  </svg>
);

export default TwitterIcon;
