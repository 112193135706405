import styled from 'styled-components';

import { colors, fontFamily, grid } from '../../ui/variables.js';

export const ExperienceStyle = styled.div`
  padding-top: 36px;

  .experience-content-wrapper {
    padding: 18px ${grid.minOuter}px 0;
    position: relative;

    .experience-title {
      transition: opacity 500ms, transform 500ms;
      opacity: 0;
      transform: translateY(-100px);

      ${fontFamily.sohneDreiviertelfett};
      font-size: 24px;
      letter-spacing: -0.36px;
      line-height: 31px;
      margin-bottom: 34px;
      padding-bottom: 8px;
      padding-right: 50px;
      position: relative;

      &.transition-title-enter,
      &.transition-title-enter-active,
      &.transition-title-enter-done {
        opacity: 1;
        transform: translateY(0);
      }

      &:after {
        background-color: ${colors.white};
        bottom: 0;
        content: '';
        display: block;
        height: 4px;
        left: 0;
        position: absolute;
        width: 86px;
      } 
    }

    .experience-code {
      background-color: ${colors.vividRed};
      border-radius: 50%;
      color: ${colors.white};
      font-size: 18px;
      height: 25px;
      letter-spacing: -0.27px;
      line-height: 19px;
      padding-top: 5px;
      position: absolute;
      right: ${grid.minOuter}px;
      text-align: center;
      top: 22px;
      width: 25px;
      transition: opacity 500ms;
      opacity: 0;

    &.transition-code-number-enter,
    &.transition-code-number-enter-active,
    &.transition-code-number-enter-done {
      opacity: 1;
    }
  }
  

/* Content */
    .content {
      opacity: 0;
      transition: opacity 1s;
      margin-bottom: 30px;
    }
    .transition-content-enter,
    .transition-content-enter-active,
    .transition-content-enter-done {
      opacity: 1;
    }

`;
