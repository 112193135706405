import React from "react";

const SmallArrow = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <polygon
      fill={color || '#fff'}
      points="5.24 15.65 3.11 13.53 8.64 8 3.11 2.47 5.24 0.35 12.89 8 5.24 15.65"
    />
  </svg>
);

export default SmallArrow;
